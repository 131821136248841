<template>
  <div class="filter-element">
    <div class="filter-label">Process</div>

    <div class="filtering">
      <VueMultiselect
        v-model="selectedProcesses"
        :options="processOptions"
        multiple
        :close-on-select="false"
        :limit="10"
        :limit-text="limitText"
        placeholder="Pick some"
        label="name"
        @remove="handleProcessRemoval"
      />
    </div>
  </div>
</template>

<script>
import VueMultiselect from 'vue-multiselect';
import { mapState, mapMutations } from 'vuex';

export default {
  name: 'ProcessFilter',

  components: {
    VueMultiselect,
  },

  props: {
    reset: { type: Number, required: true },
  },

  emits: ['selected'],

  data() {
    return {
      selectedProcesses: [],
      processOptions: [],
    };
  },

  computed: {
    ...mapState(['partLibraryFilters']),
    ...mapState('application', ['axiosInstance']),
  },

  watch: {
    selectedProcesses: {
      handler(newVal) {
        const selectedProcessesFormatted = newVal.map(process => ({
          process_id: process.process_id,
          name: process.name,
        }));
        this.$emit('selected', { processes: selectedProcessesFormatted });
      },
    },

    reset: {

      handler() {
        this.clear();
      },
    },
  },

  async mounted() {
    await this.getProcesses();
    const selected = this.partLibraryFilters.processes || [];
    if (selected.length) {
      const selectedProcesses = this.processOptions.filter(process => selected.includes(process.process_id));
      if (selectedProcesses.length) {
        this.selectedProcesses = this.ensureUniqueProcesses(selectedProcesses);
      }
    }
  },

  methods: {
    ...mapMutations(['resetFilter']),

    async getProcesses() {
      await this.axiosInstance
        .get('/api/v1/process-chain-dropdown/')
        .then(response => {
          this.processOptions = response.data
            .filter(name => name !== null)
            .map((name, index) => ({
              process_id: index,
              name: name,
            }));
        })
        .catch(error => {
          console.log(error);
        });
    },

    handleProcessRemoval(removedProcess) {
      const updatedProcesses = this.selectedProcesses.filter(
        process => process.process_id !== removedProcess.process_id
      );
      this.selectedProcesses = this.ensureUniqueProcesses(updatedProcesses);
    },

    ensureUniqueProcesses(processes) {
      const uniqueProcesses = [];
      const seenIds = new Set();

      for (const process of processes) {
        if (!seenIds.has(process.process_id)) {
          seenIds.add(process.process_id);
          uniqueProcesses.push(process);
        }
      }

      return uniqueProcesses;
    },

    clear() {
      this.resetFilter({ processes: 'processes' });
      this.selectedProcesses = [];
    },

    limitText() {
      return `...`;
    },
  },
};
</script>

<style scoped lang="scss">
select {
  padding-left: 5px;
}
</style>
