<template>
  <div class="max-w-full ml-20 mr-20 flex-1">
    <SparkInput
      v-model.lazy="name"
      name="name"
      :title="
        !lockedForUser
          ? 'Please assign a descriptive name for the part in english language only. Refrain from using numbers and special characters.'
          : lockedTitle
      "
      :disabled="lockedForUser || noFileUploaded"
      placeholder="Part Upload"
      custom="pb-0 mb-0 flex-1 pl-0 border-none hover:border-solid border-1px border-gray-200 focus:border-solid border-1px border-gray-200"
      @change="savePart()"
    />
    <div v-if="partCreator" class="text-gray-500 text-11" :title="lockedForUser ? lockedTitle : ''">
      By {{ partCreator }}, {{ partCreated }}
    </div>
  </div>
</template>

<script>
import { mapState, mapMutations } from 'vuex';

import SparkInput from '../../SparkComponents/SparkInput.vue';
export default {
  name: 'PartName',

  components: { SparkInput },

  props: {
    noFileUploaded: { type: Boolean },
    lockedForUser: { type: Boolean },
  },

  data: function () {
    return {
      name: '',
    };
  },

  computed: {
    ...mapState(['part']),
    ...mapState('application', ['lockedTitle', 'axiosInstance']),

    partCreated() {
      if (!this.part.created_date) return '';
      const date = new Date(this.part.created_date);
      return `${String(date.getDate()).padStart(2, '0')}.${String(date.getMonth() + 1).padStart(
        2,
        '0'
      )}.${date.getFullYear()}`;
    },

    partCreator() {
      return this.part.creator;
    },
  },

  watch: {
    part(newVal) {
      this.name = this.part.name;
    },
  },

  mounted() {
    this.name = this.part.name;
  },

  methods: {
    ...mapMutations(['updatePart']),
    savePart() {
      this.updatePart({ name: this.name });
      // This function will parse the current 'part' instance to the database.
      let part_id = this.part.part_id;
      let formData = {
        name: this.part.name,
      };
      this.axiosInstance.put(`/api/v1/part/${part_id}/`, formData);
    },
  },
};
</script>
