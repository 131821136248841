<template>
  <div
    class="max-w-[750px] rounded-8 flex flex-col bg-white gap-20 min-w-[750px] p-20 my-48 mx-auto border border-gray-100"
  >
    <Teleport v-if="isMounted && showPrpSidebar" to="#prp-side-bar-teleport">
      <div class="font-bold" v-text="'Evaluate Pricing'" />
      <div v-if="selectedPricingOptionUid || combinedConfigUid">
        <SparkSelect
          v-model="selectedPartId"
          :options="partList"
          label="Part"
          class="my-16"
          top-label
          name="part"
          @change="getProcessChainList"
        />

        <SparkSelect
          v-if="selectedPartId"
          v-model="selectedProcessChainId"
          :options="processChainList"
          label="Calibration process chain"
          top-label
          name="process-chain"
          @change="evaluatePricing"
        />

        <div v-if="selectedProcessChainId" class="flex flex-col gap-24 mt-40 text-right justify-end font-semibold">
          <div class="flex flex-col gap-8">
            <div v-text="'Calculated Price'" />
            <span class="text-primary-500">{{ $formatTwoDecimalPlaces(calculatedPrice) + ' €' }}</span>
          </div>
          <div class="flex flex-col gap-8">
            <div v-text="'Price Range'" />
            <span class="text-primary-500">{{
              $formatTwoDecimalPlaces(lowerPrice) + ' - ' + $formatTwoDecimalPlaces(upperPrice) + ' €'
            }}</span>
          </div>
        </div>
      </div>
    </Teleport>

    <Teleport v-if="isMounted" to="#footer-content">
      <div class="flex gap-8 justify-self-start">
        <div class="flex cursor-pointer">
          <SparkButton variant="outlined" @click="goBack">
            <div v-text="'Back'" />
          </SparkButton>
        </div>
        <SparkButton variant="outlined" @click="goToSettings">
          <div v-text="'Price Configurations'" />
        </SparkButton>
      </div>
      <div class="flex justify-self-center">
        <div />
      </div>
      <div class="flex justify-self-end gap-16">
        <SparkButton v-if="selectedPricingOptionUid" variant="outlined" @click="toggleSidebar">
          <div v-text="'Evaluate Pricing'" />
        </SparkButton>
        <SparkButton
          v-if="selectedPricingOptionUid && !isCombinedConfig"
          :disabled="!selectedPricingOptionUid"
          variant="secondary"
          @click="goToSettings"
        >
          <div v-text="'Done'" />
        </SparkButton>
      </div>
    </Teleport>

    <div class="text-14 font-semibold" v-text="'Price Configuration'" />
    <SparkInput
      v-model="selectedPricingOptionName"
      name="selectedPricingOptionName"
      title="Please assign a descriptive name for the pricing configuration."
      :disabled="selectedPricingOptionName === 'combinedConfig'"
      label="Pricing Configuration Name"
      custom="spark-input h-40 focus:h-40 text-13 rounded-4"
      @change="savePricingConfig"
    />
    <AddExpressionRuleModal
      :show="showExpressionRuleModal"
      :selected-pricing="selectedPricing"
      :mode="expressionRuleModalMode"
      :has-emtpy-rule="hasEmptyRule"
      :pricing-list="pricingList"
      @close="closeExpressionRuleModal"
      @save="assignValuesAndAddPricing"
      @update="saveEdit"
    />

    <SparkInput
      v-model="minPrice"
      type="number"
      name="minPrice"
      title="Please assign a minimum price for the pricing configuration."
      label="Minimum Price per Part [€]"
      custom="spark-input h-40 focus:h-40 text-13 rounded-4"
      @blur="savePricingConfig"
    />

    <SparkCheckbox v-model="showDeviations" name="deviations" @change="handleDeviationCheckboxChange">
      <div class="text-13" v-text="'Show price as a range'" />
    </SparkCheckbox>
    <div v-if="showDeviations" class="flex gap-12">
      <SparkInput
        v-model="lowerPriceDeviation"
        type="number"
        name="lowerPriceDeviation"
        title="You can assign a lower deviation for the pricing configuration."
        label="Lower Price Deviation [%]"
        custom="w-full h-40 focus:h-40 text-13 rounded-4"
        @blur="savePricingConfig"
      />

      <SparkInput
        v-model="upperPriceDeviation"
        type="number"
        name="upperPriceDeviation"
        title="You can assign an upper deviation for the pricing configuration."
        label="Upper Price Deviation [%]"
        custom="w-full h-40 focus:h-40 text-13 rounded-4"
        @blur="savePricingConfig"
      />
    </div>

    <div v-if="isCombinedConfig" class="mt-10 flex flex-col">
      <div class="text-15 font-semibold" v-text="'Create a Combined Pricing Configuration'" />
      <div class="text-13 mb-20" v-text="'Please select two or more single pricing configurations'" />
      <div
        v-for="pricing in pricingOptionList"
        :key="pricing.uid"
        :class="{ 'mt-4 mr-20': pricing.combined_configs?.length === 0 }"
      >
        <SparkCheckbox
          v-if="pricing.combined_configs?.length === 0"
          v-model="selectedPricingOptions"
          name="pricing"
          :value="pricing.uid"
        >
          <div class="text-13 font-normal" v-text="pricing.name" />
        </SparkCheckbox>
      </div>
      <SparkButton
        class="self-end"
        :title="saveButtonTitle"
        variant="secondary"
        :disabled="disableSaveButton"
        small
        @click="addPricing"
      >
        <div v-text="'Save'" />
      </SparkButton>
    </div>

    <div v-if="!isCombinedConfig" class="flex justify-between mt-20 items-center">
      <span class="text-14 font-semibold" v-text="'Rules'" />

      <SparkButton
        variant="plain"
        :disabled="!selectedPricingOptionName"
        :title="!selectedPricingOptionName ? 'Please add a name for the pricing configuration.' : ''"
        @click="openExpressionRuleModal"
      >
        <span class="!text-14 !font-semibold"><i class="fas fa-plus" /> Add rule </span>
      </SparkButton>
    </div>
    <div v-if="showWarningText" class="text-orange-500 text-11 font-semibold">
      <i class="fas fa-triangle-exclamation" />
      <span v-text="'There should be at least one rule without a condition or the pricing may fail.'" />
    </div>

    <ExpressionRuleContainer
      :pricing-list="updatedPricingList"
      :selected-pricing-option-uid="selectedPricingOptionUid"
      :applied-pricing="activePricing"
      @edit-pricing="editPricing"
      @duplicate-pricing="duplicatePricing"
      @delete-pricing="deletePricing"
    />
  </div>
</template>

<script>
import { mapState, mapMutations, mapActions } from 'vuex';

import AddExpressionRuleModal from './components/AddExpressionRuleModal.vue';
import ExpressionRuleContainer from './components/ExpressionRuleContainer.vue';

import SparkButton from '@/components/SparkComponents/SparkButton.vue';
import SparkCheckbox from '@/components/SparkComponents/SparkCheckbox.vue';
import SparkInput from '@/components/SparkComponents/SparkInput.vue';
import SparkSelect from '@/components/SparkComponents/SparkSelect.vue';

export default {
  name: 'Pricing',

  components: {
    SparkInput,
    SparkButton,
    SparkCheckbox,
    AddExpressionRuleModal,
    ExpressionRuleContainer,
    SparkSelect,
  },

  beforeRouteLeave(_to, _from, next) {
    if (this.showPrpSidebar) {
      this.togglePrpSidebar();
      setTimeout(() => {
        next();
      }, 300);
    } else {
      next();
    }
  },

  data() {
    return {
      isMounted: false,
      pricingOptionList: [],

      pricingList: [],
      partList: [],
      processChainList: [],
      lowerPrice: null,
      upperPrice: null,
      showDeviations: false,

      selectedPricingOptionName: '',
      selectedPricingOptionUid: '',
      activePricing: -1,
      minPrice: null,
      lowerPriceDeviation: null,
      calculatedPrice: null,
      upperPriceDeviation: null,
      combinedConfigUid: '',
      expression: '',
      rule: '',
      customText: '',
      clearInputs: false,
      selectedProcessChainId: null,
      selectedPartId: null,
      selectedPricingOptions: [],
      combinedConfigList: [],
      selectedPricingConfig: null,
      selectedPricing: null,
      showExpressionRuleModal: false,
      updatedPricingList: [],
      expressionRuleModalMode: 'add',
      mode: 'add',
      hasEmptyRule: false,
    };
  },

  computed: {
    ...mapState(['expressionOptions', 'ruleOptions']),
    ...mapState('application', ['axiosInstance', 'showPrpSidebar']),

    isCombinedConfig() {
      return this.$route.path.includes('combined');
    },

    showWarningText() {
      return this.selectedPricingOptionUid && !this.hasEmptyRule && !this.$route.path.includes('combined');
    },

    disableSaveButton() {
      return !this.selectedPricingOptionName || this.selectedPricingOptions.length < 2;
    },

    saveButtonTitle() {
      return this.disableSaveButton
        ? 'Please add at least two pricing configurations and a configuration name'
        : 'Create a Combined Pricing Configuration';
    },
  },

  watch: {
    selectedPricingOptionUid: {
      handler() {
        try {
          if (this.selectedPricingOptionUid === 'combinedConfig' || this.selectedPricingOptionUid === '') {
            this.selectedPricingOptionName = '';
            this.minPrice = 0;
            this.lowerPriceDeviation = null;
            this.upperPriceDeviation = null;
          }
          this.getPricingList();
        } catch (error) {
          console.error('Error in selectedPricingOptionUid watcher:', error);
        }
      },

      immediate: true,
    },

    pricingOptionList: {
      handler(value) {
        this.selectedPricingConfig = value.find(config => config.uid === this.selectedPricingOptionUid) ?? null;
        if (this.selectedPricingConfig) {
          this.getPricingList();
        }
      },

      immediate: true,
      deep: true,
    },

    combinedConfigList: {
      handler(value) {
        this.selectedPricingOptions = [...this.selectedPricingOptions, ...value.map(config => config.uid)];
      },

      immediate: true,
    },

    pricingList: {
      handler() {
        if (this.pricingList.length > 0) {
          this.updatedPricingList = this.processExpressionsAndRules();
          this.pricingList.forEach(pricing => {
            if (!pricing.rule || pricing.rule === '') {
              this.hasEmptyRule = true;
            } else {
              this.hasEmptyRule = false;
            }
          });
        }
      },

      immediate: true,
      deep: true,
    },

    lowerPriceDeviation: {
      handler() {
        this.showDeviations = this.lowerPriceDeviation !== null || this.upperPriceDeviation !== null;
      },

      immediate: true,
    },

    upperPriceDeviation: {
      handler() {
        this.showDeviations = this.lowerPriceDeviation !== null || this.upperPriceDeviation !== null;
      },

      immediate: true,
    },
  },

  async mounted() {
    this.isMounted = true;
    if (this.$route.query.edit) {
      this.mode = 'edit';
    } else if (this.$route.query.duplicate) {
      this.mode = 'duplicate';
    } else if (this.$route.query.evaluate) {
      this.mode = 'evaluate';
      this.toggleSidebar();
    }
    this.getPricingOptionList();
    this.getPricingList();
    this.getPartList();
    this.selectedPricingOptionUid = this.$route.params.configUid ?? '';
    if (this.$route.name.includes('combined')) {
      const uuidRegex = /[a-f0-9]{32}/i;
      const match = this.$route.fullPath.match(uuidRegex);
      if (match) {
        const uuidLikeString = match[0];
        this.combinedConfigUid = uuidLikeString;
      } else {
        this.selectedPricingOptionUid = 'combinedConfig';
      }
    }
  },

  methods: {
    ...mapMutations('application', ['togglePrpSidebar']),
    ...mapActions(['fetchPricingOptionList']),

    async savePricingConfig() {
      if (this.selectedPricingOptionUid && this.selectedPricingOptionUid !== 'combinedConfig') {
        if (!this.minPrice || this.minPrice === null || this.minPrice === undefined || this.minPrice < 0) {
          this.minPrice = 0;
        } else if (this.minPrice > 10000000) {
          this.$root.notify('error', 'Error', 'Please lower the minimum price.');
          return;
        }

        if (!this.lowerPriceDeviation || this.lowerPriceDeviation === null || this.lowerPriceDeviation === undefined) {
          this.lowerPriceDeviation = 0;
        }

        if (!this.upperPriceDeviation || this.upperPriceDeviation === null || this.upperPriceDeviation === undefined) {
          this.upperPriceDeviation = 0;
        }

        let formData = new FormData();
        formData.append('pricing_config_name', this.selectedPricingOptionName);
        formData.append('min_price', this.minPrice);
        formData.append('lower_deviation', this.lowerPriceDeviation / 100);
        formData.append('upper_deviation', this.upperPriceDeviation / 100);
        formData.append('combined_config', this.selectedPricingOptions);

        try {
          await this.axiosInstance.put(`api/v1/pricing-config/${this.selectedPricingOptionUid}/`, formData);
          this.$root.notify('success', 'Success', 'Pricing Configuration updated');
          await this.fetchPricingOptionList();
        } catch (error) {
          if (error.response.status === 400) {
            this.$root.notify('error', 'Error', 'Pricing Configuration could not be updated.');
          }
          return;
        }
      }
    },

    async saveEdit(data) {
      const item = this.pricingList[data.index];
      let expression = data.expression || item.expression;
      if (!data.expression) expression = '';
      let rule = data.rule || item.rule;
      if (!data.rule) rule = '';
      let customText = data.customText || item.custom_text;
      if (!data.customText) customText = '';

      let formData = new FormData();
      formData.append('expression', expression);
      formData.append('rule', rule);
      formData.append('custom_text', customText);
      try {
        await this.axiosInstance.put(`api/v1/pricing-edit/${item.uid}/`, formData).then(() => {
          this.$root.notify('success', 'Success', 'Pricing updated');
          this.getPricingList();
        });
      } catch (error) {
        this.$root.notify('error', `${error?.response?.data?.error_message}`, 6000);
      }
    },

    async getPricingList() {
      if (this.selectedPricingOptionUid && this.selectedPricingOptionUid !== 'combinedConfig') {
        this.selectedPricingOptionName = this.selectedPricingConfig?.label ?? '';
        this.minPrice = this.selectedPricingConfig?.min_price ?? 0;
        this.lowerPriceDeviation = this.selectedPricingConfig
          ? Math.round(this.selectedPricingConfig.lower_deviation * 100, 0)
          : null;
        this.upperPriceDeviation = this.selectedPricingConfig
          ? Math.round(this.selectedPricingConfig.upper_deviation * 100, 0)
          : null;

        try {
          const response = await this.axiosInstance.get(`api/v1/pricing-list/${this.selectedPricingOptionUid}/`);
          this.pricingList = response.data ?? [];

          this.numberOfPricings = this.pricingList.length;
          this.checkCombinedConfigs();
        } catch (error) {
          console.error('Error fetching pricing list:', error);
        }
      } else {
        this.pricingList = [];
        this.numberOfPricings = 0;
      }
    },

    async getPartList() {
      await this.axiosInstance.get('api/v1/part-list/').then(response => {
        this.partList = response.data;
        this.partList.forEach(part => {
          part.value = part.part_id;
          part.label = part.name;
        });
      });
    },

    getProcessChainList() {
      if (this.selectedPartId) {
        this.axiosInstance.get(`api/v1/process-chain-list/${this.selectedPartId}/`).then(response => {
          this.processChainList = response.data;

          this.processChainList.forEach(processChain => {
            processChain.value = processChain.process_chain_id;
            processChain.label = processChain.name;
          });
        });
      }
    },

    checkCombinedConfigs() {
      const selectedPricing = this.pricingOptionList.find(pricing => pricing.uid === this.selectedPricingOptionUid);
      if (selectedPricing && selectedPricing.combined_configs.length > 0) {
        this.combinedConfigUid = this.selectedPricingOptionUid;
        this.combinedConfigList = selectedPricing.combined_configs.map(uid => {
          const combinedConfig = this.pricingOptionList.find(pricing => pricing.uid === uid);
          return combinedConfig ? { uid: combinedConfig.uid, name: combinedConfig.name } : null;
        });
      } else {
        this.combinedConfigUid = '';
      }
    },

    async getPricingOptionList() {
      try {
        const response = await this.axiosInstance.get(`api/v1/pricing-config-list/`);
        const optionList = response.data.filter(pricing => pricing.uid !== 'cc3d0e78623d4ad58f66b3a61d4d2d59'); // filter out the default option, it should not be ediable in the cofigurator

        const options = optionList.map(pricingOption => {
          return {
            label: pricingOption.name,
            value: pricingOption.uid,
            combined_configs: pricingOption.combined_configs,
            lower_deviation: pricingOption.lower_deviation,
            upper_deviation: pricingOption.upper_deviation,
            organization: pricingOption.organization,
            uid: pricingOption.uid,
            min_price: pricingOption.min_price,
            name: pricingOption.name,
          };
        });

        const updatedOptions = [...this.pricingOptionList, ...options];

        this.pricingOptionList = [...updatedOptions];
        this.ensureUniquePricingOptions();
        this.activePricing = null;
      } catch (error) {
        console.error('Error fetching pricing options:', error);
      }
    },

    async evaluatePricing() {
      if (!this.selectedProcessChainId) {
        this.$root.notify('error', 'Error', 'Fill out all fields');
        return;
      }

      if (this.selectedPricingOptionUid) {
        try {
          let formData = new FormData();
          formData.append('min_price', this.minPrice);
          formData.append('lower_deviation', this.lowerPriceDeviation / 100);
          formData.append('upper_deviation', this.upperPriceDeviation / 100);
          await this.axiosInstance
            .post(`api/v1/pricing-config/${this.selectedProcessChainId}/${this.selectedPricingOptionUid}/`, formData)
            .then(response => {
              this.calculatedPrice = response.data.result; // Bind the calculated price
              this.lowerPrice = response.data.lower_price || this.calculatedPrice;
              this.upperPrice = response.data.upper_price || this.calculatedPrice;
              this.activePricing = response.data.active_pricing_idx; // Order index of the active pricing
              this.variablesObject = response.data;
            })
            .catch(error => {
              console.error(error.response);
              this.$root.notify('error', 'Error', `An error occurred while evaluating the pricing configuration.`);
            });
        } catch (error) {
          console.error(error);
          return;
        }
      }
    },

    async addPricing() {
      if (this.mode === 'edit' && this.combinedConfigUid) {
        if (!this.selectedPricingOptionName) {
          this.$root.notify('error', 'Error', 'Please add a name to the pricing configuration.');
          return;
        }

        if (this.selectedPricingOptions.length < 2) {
          this.$root.notify('error', 'Error', 'Please add at least two pricing configuration.');
          return;
        }

        this.savePricingConfig();
        return;
      }

      if (!this.expression && !this.customText && !this.isCombinedConfig) {
        this.$root.notify('error', 'Error', 'Please add an expression or a custom text.');
        return;
      }

      if (!this.minPrice || this.minPrice < 0) {
        this.minPrice = 0;
      } else if (this.minPrice > 10000000) {
        this.$root.notify('error', 'Error', 'Please lower the minimum price.');
        return;
      }

      if (!this.lowerPriceDeviation) {
        this.lowerPriceDeviation = 0;
      }

      if (!this.upperPriceDeviation) {
        this.upperPriceDeviation = 0;
      }

      if (!this.selectedPricingOptionUid || this.isCombinedConfig) {
        if (!this.selectedPricingOptionName) {
          this.$root.notify('error', 'Error', 'Please add a name to the pricing configuration.');
          return;
        }

        if (this.selectedPricingOptions.length < 2 && this.isCombinedConfig) {
          this.$root.notify('error', 'Error', 'Please add at least two pricing configuration.');
          return;
        }

        try {
          let formData = new FormData();
          formData.append('pricing_config_name', this.selectedPricingOptionName);
          formData.append('min_price', this.minPrice);
          formData.append('lower_deviation', this.lowerPriceDeviation / 100);
          formData.append('upper_deviation', this.upperPriceDeviation / 100);
          formData.append('combined_config', this.selectedPricingOptions);

          const response = await this.axiosInstance.post(`api/v1/pricing-config/`, formData);

          if (response.data) {
            this.$root.notify('success', 'Success', 'Pricing configuration created');
            this.selectedPricingOptionUid = response.data.uid;
            if (this.selectedPricingOptions.length) {
              this.combinedConfigUid = response.data.uid;
            }
            this.fetchPricingOptionList();
            this.getPricingOptionList();
            if (this.combinedConfigUid) {
              setTimeout(() => {
                this.$router.push({
                  name: 'pricing-combined',
                  params: { configUid: this.combinedConfigUid },
                  query: { edit: true },
                });
              }, 300);
              return;
            }
          }
        } catch (error) {
          console.error(error);
          return;
        }
      }

      if (this.selectedPricingOptionUid !== this.combinedConfigUid && !this.isCombinedConfig) {
        let formData = new FormData();
        formData.append('pricing_config_uid', this.selectedPricingOptionUid);
        formData.append('expression', this.expression);
        formData.append('rule', this.rule);
        formData.append('custom_text', this.customText);
        formData.append('min_price', this.minPrice);
        formData.append('lower_deviation', this.lowerPriceDeviation / 100);
        formData.append('upper_deviation', this.upperPriceDeviation / 100);

        try {
          const response = await this.axiosInstance.post(
            `api/v1/pricing-list/${this.selectedPricingOptionUid}/`,
            formData
          );
          this.$root.notify('success', 'Success', 'Pricing added');

          if (response.data && response.data.uid) {
            let formData = {
              pricing_order: {},
            };

            formData.pricing_order[0] = response.data.uid;

            this.pricingList
              .filter(pricing => pricing.uid !== response.data.uid)
              .forEach((pricing, index) => {
                formData.pricing_order[index + 1] = pricing.uid;
              });

            await this.axiosInstance.put(`api/v1/pricing-list/${this.selectedPricingOptionUid}/`, formData);
            await this.getPricingList();
            await this.getPricingOptionList();
          }
        } catch (error) {
          if (error.response.status === 400) {
            this.$root.notify('error', 'Error', 'Bad expression or rule.');
          }
          return;
        }
      } else {
        this.selectedPricingOptionUid = '';
        this.selectedPricingOptionName = '';
        this.combinedConfigUid = '';
        this.getPricingOptionList();
      }

      this.clearInputs = !this.clearInputs;
    },

    async deletePricing(uid) {
      await this.axiosInstance.delete(`api/v1/pricing/${uid}/`).then(() => {
        this.$root.notify('success', 'Success', 'Pricing deleted');
        this.getPricingList();
        this.getPricingOptionList();
      });

      if (this.pricingList.length === 1) {
        this.fetchPricingOptionList();
        this.$nextTick(() => {
          this.goToSettings();
        });
      }
    },

    toggleSidebar() {
      this.$nextTick(() => {
        this.togglePrpSidebar();
      });
    },

    duplicatePricing(uid) {
      const pricing = this.pricingList.find(pricing => pricing.uid === uid);
      if (!pricing) {
        return;
      }

      this.expression = pricing.expression;
      this.rule = pricing.rule;
      this.customText = pricing.custom_text || '';

      this.addPricing();
    },

    goBack() {
      this.$router.push({ name: 'pricing' });
    },

    ensureUniquePricingOptions() {
      this.pricingOptionList = this.pricingOptionList.filter(
        (config, index, self) => index === self.findIndex(c => c.uid === config.uid)
      );
    },

    goToSettings() {
      this.$router.push({ name: 'organization-settings', query: { scrollToPricing: 'true' } });
    },

    assignValuesAndAddPricing(data) {
      this.expression = data.expression;
      this.rule = data.rule;
      this.customText = data.customText;
      this.addPricing();
    },

    closeExpressionRuleModal() {
      this.showExpressionRuleModal = false;
    },

    processExpressionsAndRules() {
      if (this.pricingList?.length === 0) return [];
      return this.pricingList.map(item => {
        const expressionWords = item.expression
          ? item.expression.split(/\s+/).map(word => {
              const matchedOption = this.expressionOptions.find(option => option.variable === word);
              return matchedOption ? matchedOption.verbose : word;
            })
          : [];

        const ruleWords = item.rule
          ? item.rule.split(/\s+/).map(word => {
              const matchedOption = this.ruleOptions.find(option => option.variable === word);
              return matchedOption ? matchedOption.verbose : word;
            })
          : [];

        return {
          ...item,
          expressionWords,
          ruleWords,
        };
      });
    },

    openExpressionRuleModal() {
      this.expressionRuleModalMode = 'add';
      this.showExpressionRuleModal = true;
    },

    editPricing(pricing) {
      this.selectedPricing = pricing;
      this.expressionRuleModalMode = 'edit';
      this.showExpressionRuleModal = true;
    },

    handleDeviationCheckboxChange() {
      if (!this.showDeviations) {
        this.lowerPriceDeviation = null;
        this.upperPriceDeviation = null;
      }
    },
  },
};
</script>
