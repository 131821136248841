<template>
  <div class="border-b w-full border-gray-100">
    <div class="w-full flex p-12 items-center justify-between cursor-pointer" @click="toggleCollapse">
      <span class="text-16 font-semibold text-gray-900" v-text="title" />
      <SparkButton variant="plain" class="flex items-center justify-center p-16 text-left focus:outline-none" small>
        <SparkButtonIcon :icon="!isCollapsed ? 'fas fa-chevron-down' : 'fas fa-chevron-up'" class="text-gray-300" />
      </SparkButton>
    </div>
    <div v-show="isCollapsed" class="p-16 pt-0">
      <slot />
    </div>
  </div>
</template>

<script setup>
import { ref } from 'vue';

import SparkButton from '@/components/SparkComponents/SparkButton.vue';
import SparkButtonIcon from '@/components/SparkComponents/SparkButtonIcon.vue';

// eslint-disable-next-line no-unused-vars
const props = defineProps({
  title: {
    type: String,
    required: true,
  },
});

const isCollapsed = ref(props.title === 'General' ? true : false);

const toggleCollapse = () => {
  isCollapsed.value = !isCollapsed.value;
};
</script>
