<template>
  <SparkModal dismissable :model-value="show" @update:model-value="close">
    <div class="text-17 font-bold pb-20">{{ mode }} Category</div>
    <SparkInput v-model="newCategory.label" name="label" label="Name" />
    <div class="text-gray-400 pt-16">Properties</div>
    <div class="flex flex-col border border-gray-50 pt-8 rounded-4">
      <div class="flex justify-between p-8 border-b border-gray-50">
        <div>Required</div>
        <SparkSwitch v-model="newCategory.required" name="is_required" />
      </div>
      <div class="flex justify-between p-8">
        <div>Visible to External Users</div>
        <SparkSwitch v-model="newCategory.visible_external" name="visible_external" />
      </div>
    </div>
    <div class="pt-16">
      <div class="text-gray-400">Type</div>
      <SparkSelect
        v-model="newCategory.category_type"
        name="category_type"
        :options="categoryTypes"
        :disabled="mode === 'Edit'"
      />
    </div>

    <div v-if="newCategory.category_type === 'ComboCategory'" class="pt-16">
      <div class="text-gray-400">Options</div>
      <div class="border border-gray-50 rounded-4 flex flex-col gap-8">
        <div v-for="(item, index) in newCategory.items" :key="item" class="border-b border-gray-50 p-8">
          <div class="flex justify-between items-center">
            {{ item.value }}
            <i
              class="pr-16 text-gray-600 hover:text-gray-900 cursor-pointer fas fa-trash"
              @click="deleteComboCategoryItem(item.uid, index)"
            />
          </div>
        </div>
        <div class="flex items-center justify-between gap-8">
          <SparkInput
            v-model="newCategoryItem"
            placeholder="Add value"
            custom="border-none text-13"
            class="pl-8"
            name="options"
            label=""
            @keyup.enter="addItemAndCheckCategory"
          />
          <i
            v-if="newCategoryItem != ''"
            class="pr-24 text-gray-600 hover:text-gray-900 cursor-pointer fas fa-check"
            @click="addItemAndCheckCategory"
          />
        </div>
      </div>
    </div>

    <div class="flex justify-end gap-8 pt-16">
      <SparkButton variant="outlined" @click="close">Cancel</SparkButton>
      <SparkButton variant="secondary" @click="saveCategory">Save</SparkButton>
    </div>
  </SparkModal>
</template>

<script>
import { mapState, mapActions } from 'vuex';

import SparkButton from '@/components/SparkComponents/SparkButton.vue';
import SparkInput from '@/components/SparkComponents/SparkInput.vue';
import SparkModal from '@/components/SparkComponents/SparkModal.vue';
import SparkSelect from '@/components/SparkComponents/SparkSelect.vue';
import SparkSwitch from '@/components/SparkComponents/SparkSwitch.vue';

export default {
  name: 'AddEditCategoryModal',

  components: {
    SparkModal,
    SparkButton,
    SparkInput,
    SparkSwitch,
    SparkSelect,
  },

  props: {
    show: {
      type: Boolean,
    },

    mode: {
      type: String,
      default: 'Add',
    },

    categoryToEdit: {
      type: Object,
      default: () => ({}),
    },
  },

  emits: ['close'],

  data() {
    return {
      newCategory: {
        label: '',
        required: false,
        visible_external: false,
        items: [],
      },

      emptyCategory: {
        label: '',
        required: false,
        visible_external: false,
        items: [],
      },

      newCategoryItem: '',

      categoryTypes: [
        { label: 'Input', value: 'TextCategory' },
        { label: 'Options', value: 'ComboCategory' },
      ],
    };
  },

  computed: {
    ...mapState('application', ['axiosInstance']),
  },

  watch: {
    categoryToEdit: {
      handler(newValue) {
        if (this.mode === 'Edit') {
          this.newCategory = newValue;
        }
      },

      deep: true,
    },
  },

  methods: {
    ...mapActions(['fetchOrganization']),
    close() {
      this.$emit('close');
      this.newCategory = this.emptyCategory;
    },

    deleteComboCategoryItem(itemuid, index) {
      if (itemuid != 'new_item') {
        this.axiosInstance.delete(`/api/v1/combo-category-item/${itemuid}`).then(() => {
          this.fetchOrganization();
        });
      }

      this.newCategory.items.splice(index, 1);
    },

    addItemAndCheckCategory() {
      this.newCategory.items.push({ uid: 'new_item', value: this.newCategoryItem });
      this.newCategoryItem = '';
    },

    saveCategory() {
      if (this.mode === 'Edit') {
        if (this.newCategory.category_type == 'ComboCategory') {
          this.updateComboCategory(this.newCategory, this.newCategory.items);
        } else if (this.newCategory.category_type == 'TextCategory') {
          this.updateTextCategory(this.newCategory.uid, this.newCategory);
        }
      } else if (this.mode === 'Add') {
        if (this.newCategory.category_type == 'ComboCategory') {
          this.addComboCategory();
        } else if (this.newCategory.category_type == 'TextCategory') {
          this.addTextCategory();
        }
      }
      this.fetchOrganization();
    },

    addComboCategory() {
      this.axiosInstance.post('/api/v1/combo-category-item/', this.newCategory).then(() => {
        this.fetchOrganization();
        this.close();
      });
    },

    updateComboCategory(category, items) {
      let formData = {
        label: category.label,
        required: category.required,
        visible_external: category.visible_external,
      };
      this.axiosInstance.put(`/api/v1/combo-category/${category.uid}/`, formData).then(() => {
        this.fetchOrganization();
      });

      if (Object.values(items).some(item => item.uid === 'new_item')) {
        this.axiosInstance
          .post(`/api/v1/combo-category-item/`, this.newCategory)
          .then(() => {
            this.fetchOrganization();
          })
          .catch(error => {
            console.error('Error updating combo category item:', error);
          });
      }
      this.fetchOrganization();
      this.close();
    },

    async addTextCategory() {
      await this.axiosInstance
        .post('api/v1/text-category/', this.newCategory)
        .then(() => {
          this.fetchOrganization();
          this.close();
        })
        .catch(error => {
          console.error('Error adding text category:', error);
        });
    },

    async updateTextCategory(uid, category) {
      await this.axiosInstance.put(`/api/v1/text-category/${uid}/`, category).then(() => {
        this.fetchOrganization();
        this.close();
      });
    },
  },
};
</script>
