<template>
  <SparkModal dismissable :model-value="show" @update:model-value="close">
    <template #header>
      <div class="flex flex-col">
        <div>{{ mode }} Cost Profile</div>
      </div>
    </template>
    <template #default>
      <div>
        <SparkInput v-model="name" label="Name" name="name" />
        <div>
          <div class="text-13 font-bold py-8">Hourly Rates</div>
          <SparkInput v-model="amexpert" label="AM Expert in €" name="amexpert" />
          <SparkInput v-model="designer" label="Designer in €" name="designer" />
          <SparkInput v-model="technician" label="Technician in €" name="technician" />
        </div>
        <div>
          <div>
            <div class="text-13 font-bold pt-8 pb-4">Market Settings</div>
            <span class="text-13 text-primary-1000 p-4 bg-primary-100 rounded-4">
              These values can be used in your pricing configuration.
            </span>
          </div>
          <SparkInput v-model="overhead" class="mt-12" label="Overhead in %" name="overhead" />
          <SparkInput v-model="margin_default" label="Margin in %" name="margin_default" />
        </div>
      </div>
      <div class="flex gap-8 my-8 justify-end">
        <SparkButton variant="outlined" @click="close"><div v-text="'Cancel'" /></SparkButton>
        <SparkButton variant="secondary" @click="save"><div v-text="mode" /></SparkButton>
      </div>
    </template>
  </SparkModal>
</template>

<script>
import { mapState, mapActions } from 'vuex';

import SparkButton from '@/components/SparkComponents/SparkButton.vue';
import SparkInput from '@/components/SparkComponents/SparkInput.vue';
import SparkModal from '@/components/SparkComponents/SparkModal.vue';

export default {
  name: 'CostProfileModal',

  components: {
    SparkModal,
    SparkButton,
    SparkInput,
  },

  props: {
    show: { type: Boolean },
    mode: { type: String, default: 'Add' },
    configToEdit: { type: Object, default: null },
    uidToEdit: { type: String, default: null },
  },

  emits: ['close', 'select'],

  data() {
    return {
      uid: '',
      name: '',
      amexpert: '',
      designer: '',
      technician: '',
      overhead: '',
      margin_default: '',
    };
  },

  computed: {
    ...mapState('application', ['axiosInstance']),
  },

  watch: {
    uidToEdit() {
      if (this.mode === 'Edit') {
        this.uid = this.configToEdit.uid;
        this.name = this.configToEdit.name;
        this.amexpert = this.configToEdit.am_expert_hr;
        this.designer = this.configToEdit.designer_hr;
        this.technician = this.configToEdit.technician_hr;
        this.overhead = this.configToEdit.overhead;
        this.margin_default = this.configToEdit.margin_default;
      } else {
        this.uid = '';
        this.name = '';
        this.amexpert = '';
        this.designer = '';
        this.technician = '';
        this.overhead = '';
        this.margin_default = '';
      }
    },
  },

  methods: {
    ...mapActions(['fetchOrganization']),

    close() {
      this.$emit('close');
    },

    save() {
      let formData = new FormData();
      formData.append('name', this.name); // required
      formData.append('am_expert_hr', this.amexpert); // optional
      formData.append('designer_hr', this.designer); // optional
      formData.append('technician_hr', this.technician); // optional

      // Overhaed and Margin are legacy data. It is just taken from the actual active cost profile.
      formData.append('overhead', this.overhead); // optional
      formData.append('margin_default', this.margin_default); // optional

      if (this.mode === 'Edit') {
        // put if in editing a cost profile
        this.axiosInstance
          .put(`api/v1/costing-config/${this.configToEdit.uid}/`, formData)
          .then(() => {
            this.fetchOrganization();
            this.$root.notify('success', 'Cost profile', 'Cost profile successfully updated.', 3000);
          })
          .catch(error => {
            console.log(error.response.data);
          });
      } else {
        // post if creating a new cost profile
        this.axiosInstance
          .post(`api/v1/costing-config/`, formData)
          .then(() => {
            // console.log(response.data);
            // this.setCurrent(response.data.uid);
            this.fetchOrganization();
            this.$root.notify('success', 'Cost profile', 'Cost profile successfully created.', 3000);
          })
          .catch(error => {
            console.log(error.response.data);
          });
      }
      this.$emit('close');
    },
  },
};
</script>
