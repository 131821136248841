<template>
  <div>
    <SparkButton
      title="Saves all process chains as a set of templates. Created profiles can be accessed from the list below."
      :disabled="processChainsNotExist"
      variant="outlined"
      small
      @click="create"
    >
      <div class="flex text-13 items-center"><i class="far fa-save mr-4" /> Save as Profile</div>
    </SparkButton>
    <AddEditProfileModal
      :show="showAddProfileModal"
      :external-profile="anotherProfileUidIsExternal"
      @save="createAnalysisProfile"
      @close="closeAddProfileModal"
    />
  </div>
</template>

<script>
import { mapMutations, mapState, mapActions } from 'vuex';

import SparkButton from '../../../SparkComponents/SparkButton.vue';
import AddEditProfileModal from '../modals/AddEditProfileModal.vue';

export default {
  name: 'Profile',

  components: { SparkButton, AddEditProfileModal },

  data() {
    return {
      anotherProfileUidIsExternal: '',
      showAddProfileModal: false,
    };
  },

  computed: {
    ...mapState(['part', 'user', 'analysisProfiles']),
    ...mapState('application', ['axiosInstance']),
    processChainsNotExist() {
      return Object.keys(this.part.process_chains).length == 0;
    },
  },

  methods: {
    ...mapMutations(['updateTemplatesAndProfiles']),
    ...mapActions(['fetchAnalysisTemplatesAndProfiles']),

    async create() {
      await this.validityCheckExternalProfileText();

      this.showAddProfileModal = true;
    },

    closeAddProfileModal() {
      this.showAddProfileModal = false;
    },

    async validityCheckExternalProfileText() {
      let text = '';

      if (this.analysisProfiles && Object.keys(this.analysisProfiles).length > 0) {
        Object.keys(this.analysisProfiles).forEach(key => {
          if (this.analysisProfiles[key].restriction === 'external') {
            this.anotherProfileUidIsExternal = this.analysisProfiles[key];
            text = "⚠️ Current PRP profile '" + this.analysisProfiles[key].name + "' will be unset.";
          }
        });
      } else {
        this.anotherProfileUidIsExternal = '';
      }

      return text;
    },

    async createAnalysisProfile(profile) {
      profile['process_chain_ids'] = Object.keys(this.part.process_chains);
      this.axiosInstance
        .post(`/api/v1/analysis-profile/`, profile)
        .then(response => {
          this.updateTemplatesAndProfiles(new Date());
          this.$root.notify(
            'success',
            'Creating Profile',
            `Profile '${response.data.name}' successfully created.`,
            6000
          );
          this.fetchAnalysisTemplatesAndProfiles();
        })
        .catch(error => {
          this.$root.notifyError('Creating Profile', error);
        });
    },
  },
};
</script>
