<template>
  <div>
    <AddCustomMaterialModal
      :material-data-form="customMaterialForm"
      :material-data="materialData"
      :show="showAddCustomMaterialModal"
      mode="'add'"
      @close="showAddCustomMaterialModal = false"
      @add="addMaterial"
      @edit="editMaterial"
    />
    <EditCustomMaterialModal
      :material-data-form="customMaterialForm"
      :material-data="materialData"
      :show="showEditCustomMaterialModal"
      mode="'edit'"
      @close="showEditCustomMaterialModal = false"
      @edit="editMaterial"
    />
    <ViewMaterialModal
      :material-data="materialData"
      :filled-form-data="filledFormData"
      :show="showViewMaterialModal"
      @close="showViewMaterialModal = false"
    />
  </div>
</template>

<script>
import { mapState, mapMutations } from 'vuex';

import useCopyObject from '@/composables/copyObject.js';
import getCurrentDateWithTime from '@/composables/dateFormatting.js';
import { transformObjectToArray } from '@/helpers';
import { addCustomMaterialForm, populateFormValues } from '@/utils/material';
import AddCustomMaterialModal from '@/views/Library/material/modals/AddCustomMaterialModal.vue';
import EditCustomMaterialModal from '@/views/Library/material/modals/EditCustomMaterialModal.vue';
import ViewMaterialModal from '@/views/Library/material/modals/ViewMaterialModal.vue';

export default {
  name: 'CustomMaterial',

  components: { AddCustomMaterialModal, EditCustomMaterialModal, ViewMaterialModal },

  props: {
    materialData: { type: Object, required: true },
    type: { type: String, required: true },
  },

  emits: ['add-to-favourites'],

  data() {
    return {
      preventRetriggerFromMaterialData: false,
      showAddCustomMaterialModal: false,
      showEditCustomMaterialModal: false,
      showViewMaterialModal: false,
      customMaterialForm: addCustomMaterialForm,
      filledFormData: {},
    };
  },

  computed: {
    ...mapState([
      'popup',
      'processes',
      'materialLevel2',
      'materialLevel3',
      'materialLevel4',
      'en45545Requirements',
      'hazardLevels',
      'ul94Flammabilities',
      'nfpa130Compliancies',
      'filteredMachines',
      'allMachines',
      'user',
      'organization',
    ]),

    ...mapState('application', ['axiosInstance']),

    ...mapState({
      currentTechs: 'curTechs',
    }),

    additionalData() {
      let data = {};

      data['mat_lvl_3'] = this.materialLevel3;
      data['mat_lvl_4'] = this.materialLevel4;
      data['has_ai_prediction_model'] = this.materialData?.has_ai_prediction_model;

      return data;
    },

    formData() {
      return {
        0: {
          Name: {
            db_name: 'mat_name',
            field: 'input-text',
            options: '',
            unit: '',
            required: true,
            prc: '',
          },
        },

        1: { 'OEM Name': { db_name: 'oem_name', field: 'input-text', options: '', unit: '', required: true, prc: '' } },
        2: {
          Technology: { db_name: 'tech', field: 'select', options: this.curTechs, unit: '', required: true, prc: '' },
        },

        3: {
          Process: {
            db_name: 'prc_uid',
            field: 'select',
            options: this.processes,
            unit: '',
            required: true,
            prc: '',
          },
        },

        4: {
          'Raw Material Cost': {
            db_name: 'raw_mat_cost',
            field: 'input-number',
            options: [1, 1000000],
            unit: '€/kg',
            required: true,
            prc: '',
          },
        },

        5: {
          'Material Density': {
            db_name: 'mat_rho',
            field: 'input-number',
            options: [0.1, 20],
            unit: 'g/cm³',
            required: true,
            prc: '',
          },
        },

        6: {
          Color: {
            db_name: 'color',
            field: 'input-text',
            options: '',
            unit: '',
            required: false,
            prc: '',
          },
        },

        // 5: {
        //   "Youngs Modulus (min)": { db_name: "youngs_modulus_min", field: "input-number", options: [0.0, 100000], unit: "GPa", required: true, prc: "" },
        // },
        // 5: {
        //   "Youngs Modulus (max)": { db_name: "youngs_modulus_max", field: "input-number", options: [0.0, 100000], unit: "GPa", required: true, prc: "" },
        // },
        // 5: {
        //   "Ultimate Strength (min)": { db_name: "uts_min", field: "input-number", options: [0.0, 100000], unit: "MPa", required: true, prc: "" },
        // },
        // 5: {
        //   "Ultimate Strength (max)": { db_name: "uts_max", field: "input-number", options: [0.0, 100000], unit: "MPa", required: true, prc: "" },
        // },
        // 5: {
        //   "Elongation at Break (min)": { db_name: "elongation_at_break_min", field: "input-number", options: [0.0, 100000], unit: "-", required: true, prc: "" },
        // },
        // 5: {
        //   "Elongation at Break (max)": { db_name: "elongation_at_break_max", field: "input-number", options: [0.0, 100000], unit: "-", required: true, prc: "" },
        // },
        7: {
          'Default Machine': {
            db_name: 'mac_id',
            field: 'select',
            options: this.filteredMachines,
            unit: '',
            required: false,
            prc: '',
          },
        },

        8: {
          'Material Level 2': {
            db_name: 'mat_lvl_2_id',
            field: 'select',
            options: this.materialLevel2,
            unit: '',
            required: false,
            prc: '',
          },
        },

        // Is hidden first and will be shown if the user doesn't know the higher levels
        // 9: { "Material Level 3": { db_name: "mat_lvl_3_id", field: "select", options: this.materialLevel3, unit: "", required: false } },
        // 10: { "Material Level 4": { db_name: "mat_lvl_4_id", field: "select", options: this.materialLevel4, unit: "", required: false } },
        11: {
          'Infill Density': {
            db_name: 'infill_phi_percent',
            field: 'input-number',
            options: [0, 100],
            unit: '%',
            required: false,
            prc: 'fdm',
          },
        },

        12: {
          'Wall Thickness': {
            db_name: 'infill_custom_wall_thickness',
            // field: "infill_custom_wall_thickness",
            field: 'input-number',
            options: [0, 100000],
            unit: 'mm',
            required: false,
            prc: 'fdm',
          },
        },

        13: {
          'Relative density of support structures': {
            db_name: 'sup_phi',
            field: 'input-number',
            options: [0, 1],
            unit: '',
            required: false,
            prc: '',
          },
        },

        14: {
          'Ratio of built material, that cannot be recycled.': {
            db_name: 'c_wst',
            field: 'input-number',
            options: [0, 1],
            unit: '',
            required: false,
            prc: '',
          },
        },

        15: {
          'Minimal printable supportless overhang angle (horizontal-to-surface angle)': {
            db_name: 'min_ang',
            field: 'input-number',
            options: '',
            unit: '°',
            required: false,
            prc: '',
          },
        },

        16: {
          'Layer Height': {
            db_name: 'lyr_h',
            field: 'input-number',
            options: [0.001, 100000],
            unit: 'mm',
            required: false,
            prc: '',
          },
        },

        17: {
          'Built Rate (Core)': {
            db_name: 'bld_spd_fill',
            field: 'input-number',
            options: [0.001, 100000],
            unit: 'cm³/h',
            required: false,
            prc: '',
            source: 'ai',
          },
        },

        18: {
          'Build Rate (Contour)': {
            db_name: 'bld_spd_contour',
            field: 'input-number',
            options: [0.001, 100000],
            unit: 'cm³/h',
            required: false,
            prc: '',
          },
        },

        19: {
          'Minimal printable wall thickness': {
            db_name: 't_lim_min',
            field: 'input-number',
            options: [0.001, 100000],
            unit: 'mm',
            required: false,
            prc: '',
          },
        },

        20: {
          'Maximum printable wall thickness': {
            db_name: 't_lim_max',
            field: 'input-number',
            options: [0.001, 100000],
            unit: 'mm',
            required: false,
            prc: '',
          },
        },

        21: {
          'Minimal printable gap size': {
            db_name: 'gap_lim_min',
            field: 'input-number',
            options: [0.001, 100000],
            unit: 'mm',
            required: false,
            prc: '',
          },
        },

        22: {
          'Minimal printable size': {
            db_name: 'size_lim_min',
            field: 'input-number',
            options: [0.001, 100000],
            unit: 'mm',
            required: false,
            prc: '',
          },
        },

        23: {
          'Maximum general part size': {
            db_name: 'size_lim_max',
            field: 'input-number',
            options: [0.001, 100000],
            unit: 'mm',
            required: false,
            prc: '',
          },
        },

        24: {
          'Scaling factors to respect process and sintering shrinkage (x, y, z)': {
            db_name: 'scaling_factors',
            field: 'scaling-custom',
            options: '',
            unit: '-',
            required: false,
            prc: '',
          },
        },

        25: {
          'Bounding Box Price': {
            db_name: 'price_per_bounding_box_volume',
            field: 'input-number',
            options: [0.0, 1000000],
            unit: '€/cm³',
            required: false,
            prc: '',
          },
        },

        26: {
          'Milling rate for roughing': {
            db_name: 'milling_rate_roughing',
            field: 'input-number',
            options: [0.001, 100000],
            unit: 'cm³/h',
            required: false,
            prc: '',
          },
        },

        27: {
          'Milling rate for surface finishing': {
            db_name: 'milling_rate_finishing',
            field: 'input-number',
            options: [0.001, 100000],
            unit: 'cm²/h',
            required: false,
            prc: '',
          },
        },

        28: {
          'Minimum allowed edge radius for Sharp Edges Detection': {
            db_name: 'radii_lim_min',
            field: 'input-number',
            options: [0, 100000],
            unit: 'mm',
            required: false,
            prc: '',
          },
        },

        29: {
          'Minimum allowed mean wall thickness': {
            db_name: 't_mean_lim_min',
            field: 'input-number',
            options: [0, 100000],
            unit: 'mm',
            required: false,
            prc: '',
          },
        },

        30: {
          EN45545: {
            db_name: 'en45545',
            field: 'select',
            options: this.en45545Requirements,
            unit: '',
            required: false,
            prc: '',
          },
        },

        31: {
          'Hazard Level': {
            db_name: 'hazard_level',
            field: 'select',
            options: this.hazardLevels,
            unit: '',
            required: false,
            prc: '',
          },
        },

        32: {
          UL94: {
            db_name: 'ul94',
            field: 'select',
            options: this.ul94Flammabilities,
            unit: '',
            required: false,
            prc: '',
          },
        },

        33: {
          NFPA130: {
            db_name: 'nfpa130',
            field: 'select',
            options: this.nfpa130Compliancies,
            unit: '',
            required: false,
            prc: '',
          },
        },

        34: {
          'Thickness Range': {
            db_name: 'thickness_range',
            field: 'input-text',
            options: '',
            unit: '',
            required: false,
            prc: '',
          },
        },

        35: {
          'Safety Data Sheet': {
            db_name: 'sds_link',
            field: 'input-text',
            options: '',
            unit: '',
            required: false,
            prc: '',
          },
        },

        36: {
          Access: {
            db_name: 'restriction',
            field: 'select',
            options: this.filteredRestrictions,
            unit: '',
            required: false,
            prc: '',
          },
        },

        37: {
          'Embodied Carbon': {
            db_name: 'co2_per_kg_min',
            field: 'input-number',
            options: [-100000, 100000],
            unit: 'kgCO₂/kg',
            required: false,
            prc: '',
          },
        },
      };
    },

    curTechs() {
      // only use AM because no other materials should be created
      let actualCurTechs = useCopyObject(this.currentTechs); // Clone to not overwrite benchmark technologies
      Object.keys(actualCurTechs).forEach(key => {
        if (actualCurTechs[key].value !== 'additive_manufacturing') {
          delete actualCurTechs[key];
        }
      });
      return actualCurTechs;
    },

    processes() {
      let actualProcesses = useCopyObject(this.organization.process_synonyms);
      if (actualProcesses !== undefined) {
        Object.keys(actualProcesses).forEach(key => {
          let listToRemove = [
            'EBM',
            'DOD',
            'VDC',
            'UIM',
            'TWIM',
            'SMW LC',
            'HAND LAMINATION',
            'SDC',
            'SAND CAST',
            'RIM',
            'PVC',
            'MIM',
            'LSR',
            'LP CAST',
            'LAMINATION',
            'INC CAST',
            'GIM',
            'G CAST',
            'FORM MIL',
            'FOAM CAST',
            'FACE MIL',
            'DM',
            'CTF CAST',
            'CMT',
          ];
          if (listToRemove.includes(actualProcesses[key]?.prc_acronym)) {
            delete actualProcesses[key];
          }
        });
      }

      return Object.values(actualProcesses).map(prc => ({
        uid: prc.uid,
        value: prc.uid,
        text: prc.prc_acronym,
      }));
    },

    filteredRestrictions() {
      const userRole = this.user.user_role;
      if (userRole === 'user') {
        return {
          0: { key: 0, value: 'owner', text: 'Owner' },
        };
      } else {
        return {
          0: { key: 0, value: 'owner', text: 'Owner' },
          1: { key: 1, value: 'organization', text: 'My Organization' },
        };
      }
    },

    updatedFilteredMachines() {
      return transformObjectToArray(this.filteredMachines);
    },

    updatdedCurTechs() {
      const currentTechnology = transformObjectToArray(this.currentTechs);
      return currentTechnology.filter(tech => tech.value === 'additive_manufacturing');
    },

    updatedMaterialLevel2() {
      return transformObjectToArray(this.materialLevel2);
    },

    updatedEn45545Requirements() {
      return transformObjectToArray(this.en45545Requirements);
    },

    updatedHazardLevels() {
      return transformObjectToArray(this.hazardLevels);
    },

    updatedUl94Flammabilities() {
      return transformObjectToArray(this.ul94Flammabilities);
    },

    updatedNfpa130Compliancies() {
      return transformObjectToArray(this.nfpa130Compliancies);
    },

    updatedProcessSynonyms() {
      return this.organization.process_synonyms;
    },

    updatedFilteredRestrictions() {
      const userRole = this.user.user_role;
      if (userRole === 'user') {
        return [{ label: 'Owner', value: 'owner' }];
      } else {
        return [
          { label: 'Owner', value: 'owner' },
          { label: 'My Organization', value: 'organization' },
        ];
      }
    },

    updatedProcesses() {
      let actualProcesses = useCopyObject(this.updatedProcessSynonyms || {});

      if (actualProcesses !== undefined) {
        Object.keys(actualProcesses).forEach(key => {
          let listToRemove = [
            'EBM',
            'DOD',
            'VDC',
            'UIM',
            'TWIM',
            'SMW LC',
            'HAND LAMINATION',
            'SDC',
            'SAND CAST',
            'RIM',
            'PVC',
            'MIM',
            'LSR',
            'LP CAST',
            'LAMINATION',
            'INC CAST',
            'GIM',
            'G CAST',
            'FORM MIL',
            'FOAM CAST',
            'FACE MIL',
            'DM',
            'CTF CAST',
            'CMT',
          ];
          if (listToRemove.includes(actualProcesses[key]?.prc_acronym)) {
            delete actualProcesses[key];
          }
        });
      }

      const updatedProcesses = Object.values(actualProcesses || {}).map(prc => ({
        uid: prc.uid,
        label: prc.prc_acronym,
        value: prc.uid,
      }));

      return updatedProcesses;
    },
  },

  watch: {
    materialData: {
      handler(material) {
        let description = '';
        let linkvalue = '';
        if (material['has_ai_prediction_model']) {
          description = 'Missing values are generated by the 3D Spark AI.';
          linkvalue = 'www.3dspark.de';
        }
        if (Object.keys(material).length) {
          if (this.type === 'view') {
            this.createViewDataTable();
          } else if (this.type === 'add') {
            this.showAddCustomMaterialModal = true;
          } else if (this.type === 'edit') {
            this.mode = 'edit';
            this.showEditCustomMaterialModal = true;
          }
        }
      },

      deep: true,
    },

    popup: {
      handler(popup) {
        if (popup?.key === this.$options.name) {
          if (popup?.title === 'View Material') {
            if (popup?.clicked === 'ok' || popup?.clicked === 'cancel' || popup?.clicked === 'close') {
              this.triggerPopup('');
            }
          } else if (popup?.title === 'Edit Material') {
            if (popup?.clicked === 'ok') {
              this.triggerPopup('');
              let formData = popup?.filled_form_data;
              this.editMaterial(formData);
            } else if (popup?.clicked === 'cancel' || popup?.clicked === 'close') {
              this.triggerPopup('');
            }
          } else if (popup?.title === 'Add custom Material') {
            if (popup?.clicked === 'ok') {
              let formData = popup?.filled_form_data;
              formData = this.generateMatNameIfNotGiven(formData);
              this.triggerPopup('');
              this.addMaterial(formData);
              this.preventRetriggerFromMaterialData = true;
            } else if (popup?.clicked === 'cancel' || popup?.clicked === 'close') {
              this.triggerPopup('');
            }
          }
        }
      },

      deep: true,
    },

    updatedFilteredMachines() {
      this.populateCustomMaterialForm();
    },

    updatdedCurTechs() {
      this.populateCustomMaterialForm();
    },

    updatedMaterialLevel2() {
      this.populateCustomMaterialForm();
    },

    updatedEn45545Requirements() {
      this.populateCustomMaterialForm();
    },

    updatedHazardLevels() {
      this.populateCustomMaterialForm();
    },

    updatedUl94Flammabilities() {
      this.populateCustomMaterialForm();
    },

    updatedNfpa130Compliancies() {
      this.populateCustomMaterialForm();
    },

    updatedFilteredRestrictions() {
      this.populateCustomMaterialForm();
    },

    updatedProcessSynonyms() {
      this.populateCustomMaterialForm();
    },
  },

  mounted() {
    this.populateCustomMaterialForm();
  },

  methods: {
    ...mapMutations(['triggerPopup']),

    createViewDataTable() {
      this.filledFormData = populateFormValues(this.materialData);
      this.showViewMaterialModal = true;
    },

    generateMatNameIfNotGiven(formData) {
      if (formData === undefined) return formData;
      if (formData.mat_name === undefined) {
        let dateString = getCurrentDateWithTime().dateWithTime;
        formData.mat_name = this.user.first_name + ' ' + this.user.last_name + ' ' + dateString;
      }
      return formData;
    },

    populateCustomMaterialForm() {
      Object.values(this.customMaterialForm).forEach(section => {
        section.forEach(field => {
          if (field.db_name === 'tech') {
            field.options = this.updatdedCurTechs;
          } else if (field.db_name === 'prc_uid') {
            field.options = this.updatedProcesses;
          } else if (field.db_name === 'mac_id') {
            field.options = this.updatedFilteredMachines;
          } else if (field.db_name === 'mat_lvl_2_id') {
            field.options = this.updatedMaterialLevel2;
          } else if (field.db_name === 'en45545') {
            field.options = this.updatedEn45545Requirements;
          } else if (field.db_name === 'hazard_level') {
            field.options = this.updatedHazardLevels;
          } else if (field.db_name === 'ul94') {
            field.options = this.updatedUl94Flammabilities;
          } else if (field.db_name === 'nfpa130') {
            field.options = this.updatedNfpa130Compliancies;
          } else if (field.db_name === 'restriction') {
            field.options = this.updatedFilteredRestrictions;
          }
        });
      });
    },

    addMaterial(formData) {
      this.axiosInstance
        .post('/api/v1/material/', formData)
        .then(response => {
          let message = 'Custom Material ' + response.data.mat_name + ' created';
          this.$root.notify('success', 'Add Material', message, 3000);
          this.$emit('add-to-favourites', response.data);
          this.$parent.highlightNewMaterial(response.data.mat_id);
        })
        .catch(error => {
          this.$root.notify('error', 'Add Material', error.response?.data.error_message, 3000);
        });
    },

    editMaterial(formData) {
      this.axiosInstance
        .put(`/api/v1/material/${formData.mat_id}/`, formData)
        .then(response => {
          if (response && response.data) {
            this.$root.notify('success', 'Edit Material ' + response.data.mat_name + ' edited', 3000);
            this.$emit('add-to-favourites', response.data);
            this.$parent.highlightNewMaterial(response.data.mat_id);
          }
        })
        .catch(error => {
          if (error && error.response && error.response.data) {
            this.$root.notify('error', error.response.data.error_message, 3000);
          } else {
            this.$root.notify('error', 'An unknown error occurred', 3000);
          }
        });
    },
  },
};
</script>
