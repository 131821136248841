<template>
  <div class="flex flex-col pl-8" @click="selectProcessChain()">
    <div class="flex items-center font-bold">
      <div class="truncate w-[300px]">{{ chain.name }}</div>

      <SparkBadge v-if="!analysisFinished" class="mr-4" variant="outlined">Estimation</SparkBadge>
      <MainBenchmarkBadge class="mr-4" :chain="chain" />
    </div>
    <div v-if="mouseHover === 'name'">
      <div class="text-primary-500" @click="trigger()"><i class="fas fa-arrow-right pr-8" />Edit</div>
    </div>
    <div v-else class="text-gray-300 flex items-center truncate w-[300px]">
      {{ actualProcessSynonym }} <i class="far fa-circle text-[3px] mx-4" /> {{ chain.material_name }}
      <i class="far fa-circle text-[3px] mx-4" /> {{ chain.machine_name }}
    </div>

    <ChangeProcessChainPopup :show="showPopup" :chain="chain" @close="closePopup" />
  </div>
</template>

<script>
import { mapMutations, mapState } from 'vuex';

import SparkBadge from '../../../../SparkComponents/SparkBadge.vue';

import ChangeProcessChainPopup from './components/ChangeProcessChainPopup.vue';
import MainBenchmarkBadge from './components/MainBenchmarkBadge.vue';

import { analysisFinished } from '@/composables/processChainHandling.js';

export default {
  name: 'ProcessChainName',

  components: {
    MainBenchmarkBadge,
    SparkBadge,
    ChangeProcessChainPopup,
  },

  props: {
    chain: { type: Object, required: true },
    mouseHover: { type: String, default: '' },
  },

  data() {
    return {
      showPopup: false,
    };
  },

  computed: {
    ...mapState(['user', 'organization']),
    analysisFinished() {
      return analysisFinished(this.chain.process_chain_id);
    },

    processSynonyms() {
      return this.organization.process_synonyms;
    },

    actualProcessSynonym() {
      return this.processSynonyms[this.chain.prc_uid]?.prc_acronym;
    },
  },

  watch: {
    popup(popup) {
      if (popup?.key == this.$options.name) {
        if (popup?.clicked == 'ok') {
          this.triggerPopup('');
        } else if (popup?.clicked == 'cancel' || popup?.clicked == 'close') {
          this.triggerPopup('');
        }
      }
    },
  },

  methods: {
    ...mapMutations(['triggerPopup', 'changeInvestigationDetails']),

    selectProcessChain() {
      this.changeInvestigationDetails({ uid: this.chain.process_chain_id });
    },

    trigger() {
      this.showPopup = true;
      // this.triggerPopup({
      //   key: this.$options.name,
      //   show: true,
      //   title: 'Change Inputs',
      //   type: 'component',
      //   buttons: true,
      //   buttonContent: [{ text: 'Close', type: 'outlined', value: 'cancel' }],
      //   data: markRaw(ChangeProcessChainPopup),
      // });
    },

    closePopup() {
      this.showPopup = false;
    },
  },
};
</script>
