<template>
  <div
    class="flex items-center py-4 min-w-0 w-full"
    @mouseenter="mouseHover = true"
    @mouseleave="mouseHover = false"
    @click="selected"
  >
    <AnimationSuggestion
      :creation-in-progress="creationInProgress"
      :creation-finished="creationFinished"
      :mouse-hover="mouseHover"
    />
    <TemplateDetails :template="template" class="flex-grow" />
  </div>
</template>

<script>
import { mapState } from 'vuex';

import AnimationSuggestion from './AnimationSuggestion.vue';
import TemplateDetails from './TemplateDetails.vue';

export default {
  name: 'TemplateRow',

  components: { AnimationSuggestion, TemplateDetails },

  props: { template: { type: Object, default: () => ({}) }, loadingUid: { type: String, default: '' } },

  emits: ['selected-template'],

  data() {
    return {
      mouseHover: false,
      creationInProgress: false,
      creationFinished: false,
      selectable: true,
    };
  },

  computed: {
    ...mapState(['part']),

    cadUploadedOrCadless() {
      return this.part.part_id != 0 && (this.part.basename != '' || this?.part.is_cadless);
    },
  },

  watch: {
    loadingUid(newVal, oldVal) {
      if (newVal == 'abort') {
        this.creationInProgress = false;
        this.selectable = false;
      } else if (oldVal == this.template.uid && newVal == '') {
        this.creationInProgress = false;
        this.creationFinished = true;
        setTimeout(() => {
          this.creationFinished = false;
        }, 3000);
      }
    },
  },

  methods: {
    selected() {
      if (this.selectable) {
        this.$emit('selected-template');
        if (this.cadUploadedOrCadless) {
          this.creationInProgress = true;
        }
      }
      this.selectable = false;
      setTimeout(() => {
        this.selectable = true;
      }, 3000);
    },
  },
};
</script>
