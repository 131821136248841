export function getPrpState() {
  return {
    selectedPrpParts: {},
    prpParts: [],
    prpPartLibraryData: {},
    prpPartAnalysisDone: false,
    prpOrderCategories: {},
    processChainUpdated: false,
    prpCategoryList: [],
  };
}
export default getPrpState();
