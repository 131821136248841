<template>
  <teleport v-if="isMounted" to="#sub-navigation__action-buttons">
    <div class="flex align-middle justify-center gap-8">
      <LibraryFilter @filter="getFilteredMaterials($event)" />
      <div class="mt-4">
        <DropdownMenu
          :list-options="tableDropdownOptions"
          title-icon="fas fa-ellipsis-h"
          close-click-style="always"
          class="border border-solid border-gray-10 rounded-8 pl-4"
          @update-option="tableAction($event)"
        />
      </div>
    </div>
  </teleport>
  <div class="flex-row table-title">
    <CustomMaterial :material-data="materialData" :type="type" @add-to-favourites="addToFavourites($event)" />
  </div>
  <SparkTable
    v-if="materials.length > 0"
    :table-rows="materials"
    :selected-columns="selectedMaterialLibraryColumns"
    :current-order-reverse="currentOrderReverse"
    :dropdown-options="dropdownOptions"
    parent="materials"
    :current-order="currentOrder"
    :highlighted-materials="highlightedMaterials"
    :current-page="currentPage"
    :max-page-number="numberOfPages"
    :number-per-page="numberPerPage"
    :total-count="totalNumberOfMaterials"
    @add-to-favourites="addToFavourites($event)"
    @remove-from-favorites="removeFromFavorites($event)"
    @add-to-in-house="addToInHouse($event)"
    @remove-from-in-house="removeFromInHouse($event)"
    @dropdown-event="materialEvent($event)"
    @sort="sort($event)"
    @update-current-page="updateTable"
  />
  <img
    v-else-if="materials.length === 0 && loading === true"
    src="@/assets/img/loading.gif"
    class="w-60 mx-auto my-auto"
  />
  <div v-else>
    <p>No materials found.</p>
  </div>
</template>

<script>
import { mapState, mapGetters, mapMutations } from 'vuex';

import LibraryFilter from '../../components/LibraryFilter.vue';

import CustomMaterial from './CustomMaterial.vue';

import SparkTable from '@/components/PartLibrary/SparkTable.vue';
import DropdownMenu from '@/components/Reusable/DropdownMenu.vue';

export default {
  name: 'MaterialTable',

  components: {
    CustomMaterial,
    DropdownMenu,
    LibraryFilter,
    SparkTable,
  },

  emits: ['mat-id', 'materialChangeNotAllowed'],

  data() {
    return {
      isMounted: false,
      materials: [],
      currentPage: undefined,
      numberOfPages: 1,
      filterParams: {},
      currentOrder: '',
      currentOrderReverse: true,
      materialData: {},
      highlightedMaterials: [],
      type: 'view',
      defaultDropdownOptions: [
        { text: 'View Details', value: 'view', icon: 'fas fa-glasses', disabled: false },
        { text: 'Add Similar Material', value: 'add', icon: 'fas fa-plus', disabled: false },
        { text: 'Edit Material', value: 'edit', icon: 'fas fa-pen', disabled: true },
        { text: 'Delete Material', value: 'delete', icon: 'fas fa-trash', disabled: true },
      ],

      ownDropdownOptions: [
        { text: 'View Details', value: 'view', icon: 'fas fa-glasses', disabled: false },
        { text: 'Add Similar Material', value: 'add', icon: 'fas fa-plus', disabled: false },
        { text: 'Edit Material', value: 'edit', icon: 'fas fa-pen', disabled: false },
        { text: 'Delete Material', value: 'delete', icon: 'fas fa-trash', disabled: false },
      ],

      dropdownOptions: {},
      tableDropdownOptions: [{ text: 'Edit Columns', value: 'editColumns', icon: '', disabled: false }],
      loading: false,
      numberPerPage: 0,
      totalNumberOfMaterials: 0,
    };
  },

  computed: {
    ...mapState(['part', 'user', 'investigationDetails', 'popup', 'organization']),
    ...mapState('application', ['lockedTitle', 'axiosInstance']),
    ...mapGetters(['lockedForUser']),

    username() {
      return this.user.username;
    },

    activeInvestigationDetails() {
      return this.investigationDetails;
    },

    processChains() {
      return this.part.process_chains;
    },

    selectedMatId() {
      return this.processChains[this.activeInvestigationDetails.uid]?.mat_id;
    },

    materialLibraryColumns: {
      get() {
        let cols = this.user.user_settings.material_library_columns;
        // if (this.user.is_external === true) {
        //   cols = Object.fromEntries(Object.entries(cols).filter(([key, value]) => value.external === true));
        // }
        return cols;
      },

      set(columns) {
        this.setMaterialUserSettings();
        this.updateMaterialUserSettings(columns);
      },
    },

    // 2. get selected Part Library Columns
    selectedMaterialLibraryColumns() {
      let shown = [];
      for (let col in this.materialLibraryColumns) {
        if (this.materialLibraryColumns[col].selected === true) {
          shown.push(this.materialLibraryColumns[col]);
        }
      }
      // sort selected Columns
      shown.sort((a, b) => {
        return a.index - b.index;
      });
      return shown;
    },
  },

  watch: {
    popup(popup) {
      if (popup?.key === this.$options.name) {
        if (popup?.clicked === 'ok') {
          this.triggerPopup('');
          this.setMaterialUserSettings();
          this.updateMaterialUserSettings(this.materialLibraryColumns);
        } else if (popup?.clicked === 'cancel' || popup?.clicked === 'close') {
          this.triggerPopup('');
        }
      }
    },
  },

  mounted() {
    this.isMounted = true;
    this.getMaterials();
  },

  methods: {
    ...mapMutations(['updateMaterialUserSettings', 'triggerPopup']),

    addToFavourites(material) {
      this.axiosInstance
        .post(`api/v1/user-favorite-material/${material.mat_id}/`)
        .then(() => {
          material.favorite = true;
          this.getMaterials();
        })
        .catch(error => {
          if (error.response) {
            console.error(JSON.stringify(error));
          }
        });
    },

    removeFromFavorites(material) {
      this.axiosInstance
        .delete(`api/v1/user-favorite-material/${material.mat_id}/`)
        .then(() => {
          material.favorite = false;
        })
        .catch(error => {
          if (error.response) {
            console.error(JSON.stringify(error));
          }
        });
    },

    addToInHouse(material) {
      if (this.user.user_role !== 'manager') {
        this.$root.notify('warning', 'Only managers can edit the in-house status.', 3000);
        return;
      }
      this.axiosInstance
        .post(`api/v1/organization-inhouse-material/${material.mat_id}/`)
        .then(() => {
          material.inhouse = true;
          this.getMaterials();
        })
        .catch(error => {
          if (error.response) {
            console.error(JSON.stringify(error));
          }
        });
    },

    removeFromInHouse(material) {
      if (this.user.user_role !== 'manager') {
        this.$root.notify('warning', 'Only managers can edit the in-house status.', 3000);
        return;
      }
      this.axiosInstance
        .delete(`api/v1/organization-inhouse-material/${material.mat_id}/`)
        .then(() => {
          material.inhouse = false;
        })
        .catch(error => {
          if (error.response) {
            console.error(JSON.stringify(error));
          }
        });
    },

    highlightNewMaterial(mat_id) {
      this.highlightedMaterials.push(mat_id);
      setTimeout(() => {
        const index = this.highlightedMaterials.indexOf(mat_id);
        if (index > -1) {
          this.highlightedMaterials.splice(index, 1);
        }
      }, 60000); // Remove highlight after 1 minute
    },

    tableAction() {
      this.triggerPopup({
        key: this.$options.name,
        show: true,
        title: 'Edit Columns',
        selectOptions: this.materialLibraryColumns,
        type: 'multi',
        buttons: true,
        buttonContent: [
          { text: 'Cancel', type: 'outlined', value: 'cancel' },
          { text: 'Ok', type: 'secondary', value: 'ok' },
        ],
      });
    },

    calculateDropownOptions() {
      this.materials.forEach(mat => {
        if (mat.owner_username && (mat.owner_username === this.username || this.user.user_role === 'manager')) {
          this.dropdownOptions[mat.mat_id] = this.ownDropdownOptions;
        } else {
          this.dropdownOptions[mat.mat_id] = this.defaultDropdownOptions;
        }
      });
    },

    deleteMaterial(material) {
      this.axiosInstance
        .delete(`api/v1/material/${material.mat_id}/`)
        .then(response => {
          this.getMaterials();
          this.$root.notify('success', response.data, 3000);
        })
        .catch(error => {
          if (error.response) {
            this.$root.notify('warning', error.response.data.error_message, 3000);
          }
        });
    },

    getMaterial(material) {
      this.axiosInstance
        .get(`api/v1/material-structured/${material.mat_id}`)
        .then(response => {
          this.materialData = {
            ...response.data,
            ...response.data.properties_am,
            ...response.data.properties_milling,
            ...response.data.properties_casting,
            ...response.data.properties_fire_and_smoke,
          };
        })
        .catch(error => {
          console.error(error);
        });
    },

    updateTable(pageNumber) {
      this.currentPage = pageNumber;
      this.getMaterials();
    },

    sort(str) {
      this.currentOrder = str;
      this.currentOrderReverse = !this.currentOrderReverse;
      this.filterParams['order_by'] = this.currentOrder;
      this.filterParams['order_reverse'] = this.currentOrderReverse;
      this.getMaterials();
    },

    getFilteredMaterials(event) {
      this.filterParams['filter_value'] = event;
      this.getMaterials();
    },

    addPrcAcronym(obj) {
      Object.keys(obj).forEach(key => {
        let prc_uid = obj[key]['prc_uid'];
        obj[key]['prc_acronym'] = this.organization.process_synonyms[prc_uid].prc_acronym;
      });
    },

    getMaterials() {
      let url = 'api/v1/material-library/';
      if (this.currentPage !== undefined) {
        url = `api/v1/material-library/?page=${this.currentPage}`;
      } else if (this.selectedMatId !== undefined) {
        url = `api/v1/material-library/${this.selectedMatId}/`;
      }
      // get paginated materials and not all via a prop
      this.loading = true;
      this.axiosInstance
        .get(url, {
          params: this.filterParams,
        })
        .then(response => {
          this.numberPerPage = response.data.per_page;
          this.totalNumberOfMaterials = response.data.count;
          this.materials = response.data.results;
          this.addPrcAcronym(this.materials);
          this.numberOfPages = response.data.num_pages;
          this.currentPage = Number(response.data.page_number);
          this.calculateDropownOptions();
          this.loading = false;
        })
        .catch(error => {
          console.error(error);
        });
    },

    materialEvent({ event, row: material }) {
      this.type = event.value;
      if (event.value === 'add') {
        this.getMaterial(material);
      }
      if (event.value === 'delete') {
        if (material.owner_username != this.username && this.user.user_role !== 'manager') {
          this.$root.notify(
            'warning',
            'This Material cannot be deletetd. You can only delete your own material.',
            3000
          );
        } else {
          this.deleteMaterial(material);
        }
      }
      if (event.value === 'edit') {
        if (material.owner_username != this.username && this.user.user_role !== 'manager') {
          this.$root.notify('warning', 'This Material cannot be edited. You can only delete your own material.', 3000);
        } else {
          this.getMaterial(material);
        }
      }
      if (event.value === 'view') {
        this.getMaterial(material);
      }
    },

    async setMaterialUserSettings() {
      this.axiosInstance.put('/api/v1/user-settings/', {
        material_library_columns: this.materialLibraryColumns,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
th {
  height: 55px;
}

.checkbox-input {
  cursor: pointer !important;
  width: 13px;
  height: 13px;
  padding-left: 0;
}

.spark-table tr td {
  padding-right: 16px;
}

.highlighted-material {
  background-color: rgb(232, 255, 230);
  font-weight: bold;
}

.container {
  display: flex;
  flex-direction: column;
  font-size: var(--12px);
  justify-content: start;
}
</style>
