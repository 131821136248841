<template>
  <Teleport v-if="isMounted" to="#footer-content">
    <div class="flex justify-self-start">
      <div class="flex cursor-pointer">
        <div />
      </div>
    </div>
    <div class="flex justify-self-center">
      <div />
    </div>
    <div class="flex justify-self-end gap-16">
      <SparkButton v-if="isSummary" variant="secondary" @click="backButtonClicked">
        <div v-text="'Back'" />
      </SparkButton>
      <SparkButton
        :disabled="!prpParts.length || !categoriesValid || (!orderCategoriesValid && isSummary)"
        variant="secondary"
        @click="proceedButtonClicked"
      >
        <div v-text="proceedButtonText" />
      </SparkButton>
    </div>
  </Teleport>

  <Parts v-if="prpParts?.length" :parts="prpParts" :is-summary="isSummary" />
  <FileUpload v-else-if="!isSummary" />
  <div class="text-red-500 text-13 bg-red-50 rounded-8 my-8 p-4">
    <ul>
      <li v-for="(line, index) in prpFootnotes" :key="index" v-text="line" />
    </ul>
  </div>
  <CustomerInfo v-if="isSummary && hasOrderCategories" />
  <OrderConfirmationModal :show="showOrderConfirmationModal" @close="closeConfirmationModal" />
</template>

<script>
import { mapState, mapActions, mapMutations } from 'vuex';

import SparkButton from '../../components/SparkComponents/SparkButton.vue';

import CustomerInfo from './components/CustomerInfo.vue';
import FileUpload from './components/FileUpload.vue';
import OrderConfirmationModal from './components/modals/OrderConfirmationModal.vue';
import Parts from './components/Parts.vue';
export default {
  name: 'PartRequestPortal',

  components: { SparkButton, FileUpload, Parts, OrderConfirmationModal, CustomerInfo },

  data() {
    return {
      isMounted: false,
      currentPage: 1,
      loading: true,
      showOrderConfirmationModal: false,
      isSummary: false,
    };
  },

  computed: {
    ...mapState('prp', ['prpParts', 'prpOrderCategories']),
    ...mapState(['partLibraryFilters', 'partUploadError', 'user', 'organization']),
    ...mapState('application', ['axiosInstance', 'showPrpSidebar']),

    categoriesValid() {
      return this.prpParts.every(part => {
        return (
          Object.values(part.categories.combo_categories)
            .filter(category => category.required)
            .every(category => category.value) &&
          Object.values(part.categories.text_categories)
            .filter(category => category.required)
            .every(category => category.value)
        );
      });
    },

    prpFootnotes() {
      if (this.organization.prp_footnotes) {
        return this.organization.prp_footnotes.split('\n');
      } else {
        return [];
      }
    },

    orderCategoriesValid() {
      return (
        Object.values(this.prpOrderCategories?.prp_combo_categories)
          .filter(category => category.required)
          .every(category => category.value) &&
        Object.values(this.prpOrderCategories.prp_text_categories)
          .filter(category => category.required)
          .every(category => category.value)
      );
    },

    hasOrderCategories() {
      if (Object.keys(this.prpOrderCategories).length === 0) return false;
      return (
        Object.keys(this.prpOrderCategories?.prp_combo_categories).length > 0 ||
        Object.keys(this.prpOrderCategories?.prp_text_categories).length > 0
      );
    },

    proceedButtonText() {
      if (this.isSummary) {
        return 'Request Offer';
      } else {
        return 'Next';
      }
    },
  },

  watch: {
    partUploadError(value) {
      if (value) {
        this.deletePart(value);
      }
    },
  },

  async mounted() {
    this.isMounted = true;
    if (this.prpParts?.length === 0) {
      let formData = JSON.parse(JSON.stringify(this.partLibraryFilters));
      formData = { ...formData, ...{ page: this.currentPage }, ...{ not_checked_out: true } };
      await this.fetchPrpPartLibraryData(formData);
      await this.fetchPrpOrderData();
    }
  },

  beforeUnmount() {
    if (this.showPrpSidebar) {
      this.togglePrpSidebar();
    }
  },

  methods: {
    ...mapActions('prp', ['fetchPrpPartLibraryData', 'fetchPrpOrderData']),
    ...mapMutations(['setPartUploadError']),
    ...mapMutations('application', ['togglePrpSidebar']),

    async checkOutParts() {
      let formData = JSON.parse(JSON.stringify(this.partLibraryFilters));
      formData = { ...formData, ...{ check_out: true }, ...{ query_type: 'user' } };
      await this.axiosInstance
        .put('api/v1/prp-part/', formData)
        .then(() => {
          this.loading = false;
          if (this.showPrpSidebar) {
            this.togglePrpSidebar();
          }
          this.openOrderConfirmationModal();
        })
        .catch(error => {
          console.error(error);
        });
      this.$root.notify('success', 'Checkout', 'The parts were successfully checked out.', 3000);
      // Change formData to fetch parts that are not checked out
      formData = { ...formData, ...{ not_checked_out: true }, ...{ not_checked_out: true } };
      await this.fetchPrpPartLibraryData(formData);
      this.isSummary = false;
    },

    closeConfirmationModal() {
      this.showOrderConfirmationModal = false;
    },

    async deletePart(partId) {
      await this.axiosInstance.delete(`/api/v1/part/${partId}/`).then(() => {
        this.setPartUploadError('');
      });
    },

    openOrderConfirmationModal() {
      this.showOrderConfirmationModal = true;
    },

    proceedButtonClicked() {
      if (this.showPrpSidebar) {
        this.togglePrpSidebar();
      }

      if (this.isSummary) {
        this.checkOutParts();
      } else {
        this.isSummary = true;
      }
    },

    backButtonClicked() {
      this.isSummary = false;
    },
  },
};
</script>
