<script>
import _refiner from 'refiner-js';
import { mapState } from 'vuex';

export default {
  name: 'RefinerDialog',

  props: {
    formId: {
      type: String,
      default: '',
    },

    show: {
      type: Number,
      default: 0,
    },

    messageIdentifier: {
      type: String,
      default: 'if_you_could_add_one_thing_what_would_it_be',
    },
  },

  computed: {
    ...mapState('application', ['axiosInstanceFileUpload']),
    ...mapState(['user', 'organization']),

    currentRouteName() {
      return this.$route.name;
    },
  },

  watch: {
    show() {
      if (this.organization.name !== 'team_spark') {
        this.showRefiner();
      }
    },
  },

  methods: {
    showRefiner() {
      _refiner('showForm', this.formId);
      _refiner('onComplete', (formId, responseData) => {
        this.sendIssue(formId, responseData);
      });
    },

    async sendIssue(id, responseData) {
      let formData = new FormData();
      let message = responseData?.[this.messageIdentifier];
      if (message != '') {
        formData.append('ticket_origin', 'Refiner');
        formData.append('title', 'Refiner Survey' + id);
        formData.append('message', message);
        formData.append('part_id', '');

        formData.append('username', this.user);
        formData.append('issue_type', 'New Feature');
        formData.append('view', this.currentRouteName);
        formData.append('current_url', window.location.href);

        await this.axiosInstanceFileUpload.post('/api/v1/issue/', formData).then(() => {});
      }
    },
  },

  render() {
    return [];
  },
};
</script>
