<template>
  <div class="flex p-20">
    <div class="flex flex-col w-1/3">
      <div class="text-15 font-bold">Categories</div>
      <div class="text-13 text-primary-500 cursor-pointer hover:text-primary-600" @click="addNewCategory()">Add</div>
    </div>
    <div class="border border-gray-50 rounded-8 w-2/3">
      <div class="flex w-full text-13 text-gray-600 border-b border-gray-50 h-56 items-center">
        <div class="w-[96px] ml-24"><!-- For drag icon column --></div>
        <div class="w-[200px]">Category</div>
        <div class="w-[96px]">Required</div>
        <div class="w-[96px]">Available in PRP</div>
        <div class="flex-1">Options</div>
        <div class="w-[96px] text-center"><!-- For actions column --></div>
      </div>
      <draggable :list="categoryList" item-key="order_index" class="w-full" @end="reindexCategories">
        <template #item="{ element }">
          <div class="draggable-item flex text-13 h-64 items-center w-full border-b border-gray-50">
            <div class="w-[96px] drag-icon ml-24">&#x2630;</div>
            <div class="w-[200px]">
              <div class="text-gray-1000 font-bold">{{ element.label }}</div>
              <div v-if="element.category_type === 'ComboCategory'" class="text-11 text-gray-400">Options</div>
              <div v-else class="text-11 text-gray-400">Input</div>
            </div>
            <div class="w-[96px]">
              <SparkBadge v-if="element.required" variant="success" class="w-fit">
                <i class="fas fa-check" />
              </SparkBadge>
              <SparkBadge v-else variant="warning" class="w-fit">
                <i class="fas fa-xmark" />
              </SparkBadge>
            </div>
            <div class="w-[96px]">
              <SparkBadge v-if="element.visible_external" class="w-fit" variant="success">
                <i class="fas fa-check" />
              </SparkBadge>
              <SparkBadge v-else class="w-fit" variant="warning">
                <i class="fas fa-xmark" />
              </SparkBadge>
            </div>
            <div
              v-if="element.category_type === 'ComboCategory'"
              :title="formatItemsList(element.items)"
              class="flex-1 flex flex-wrap text-gray-600"
            >
              <div v-for="(item, index) in element.items" :key="item">
                <div v-if="index === 0">{{ item.value }},&nbsp;</div>
                <div v-if="index === 1">{{ item.value }},&nbsp;</div>
                <div v-if="index === 2">{{ item.value }}&nbsp;</div>
              </div>
              <div v-if="Object.keys(element.items).length > 3">+ {{ Object.keys(element.items).length - 3 }} more</div>
            </div>
            <div v-else class="flex-1">&nbsp;</div>
            <div class="w-[96px] text-center">
              <DropdownMenu
                title-icon="fas fa-ellipsis-v"
                :list-options="[
                  { text: 'Edit', icon: 'far fa-edit', value: 'edit' },
                  { text: 'Delete', icon: 'far fa-trash-alt', value: 'delete' },
                ]"
                @update-option="handleDropdownAction($event, element)"
              />
            </div>
          </div>
        </template>
      </draggable>

      <DeleteCategoryModal
        :show="showDeleteCategoryModal"
        :category="categoryToDelete"
        @close="closeDeleteCategoryModal"
      />

      <AddEditCategoryModal
        :show="showAddEditCategoryModal"
        :category-to-edit="categoryToEdit"
        :mode="mode"
        @close="closeAddEditCategoryModal"
      />
    </div>
  </div>
</template>

<script>
import draggable from 'vuedraggable';
import { mapState, mapActions } from 'vuex';

import AddEditCategoryModal from './components/AddEditCategoryModal.vue';
import DeleteCategoryModal from './components/DeleteCategoryModal.vue';

import DropdownMenu from '@/components/Reusable/DropdownMenu.vue';
import SparkBadge from '@/components/SparkComponents/SparkBadge.vue';

export default {
  name: 'CategorySettings',

  components: {
    draggable,
    SparkBadge,
    DropdownMenu,
    DeleteCategoryModal,
    AddEditCategoryModal,
  },

  data() {
    return {
      categoryList: [],
      showDeleteCategoryModal: false,
      categoryToDelete: {},
      showAddEditCategoryModal: false,
      mode: 'Add',
      categoryToEdit: {},
    };
  },

  computed: {
    ...mapState(['user', 'organization']),
    ...mapState('application', ['axiosInstance']),
  },

  watch: {
    organization: {
      handler() {
        this.getCategoryList();
      },

      deep: true,
    },
  },

  async mounted() {
    await this.getCategoryList();
    this.fetchOrganization();
  },

  methods: {
    ...mapActions(['fetchOrganization']),
    handleDropdownAction(option, element) {
      if (option.value === 'edit') {
        this.categoryToEdit = element;
        this.mode = 'Edit';
        this.showAddEditCategoryModal = true;
      } else if (option.value === 'delete') {
        this.categoryToDelete = element;
        this.showDeleteCategoryModal = true;
      }
    },

    closeDeleteCategoryModal() {
      this.showDeleteCategoryModal = false;
      this.fetchOrganization();
    },

    async getCategoryList() {
      await this.axiosInstance.get(`api/v1/category-order/`).then(response => {
        this.categoryList = response.data['category_list'];
      });
    },

    addNewCategory() {
      this.mode = 'Add';
      this.showAddEditCategoryModal = true;
    },

    closeAddEditCategoryModal() {
      this.showAddEditCategoryModal = false;
      this.fetchOrganization();
    },

    reindexCategories() {
      let formData = {
        category_order: {},
      };

      const list = this.categoryList;
      list.forEach((category, index) => {
        category.order_index = index;
        formData.category_order[index] = {
          uid: category.uid,
          category_type: category.category_type,
        };
      });

      this.axiosInstance.put(`api/v1/category-order/`, formData);
    },

    formatItemsList(items) {
      if (!items?.length) return '';
      return items.map(item => item.value).join(', ');
    },
  },
};
</script>

<style lang="scss" scoped>
.drag-icon:hover {
  cursor: grab;
}

.dragging .drag-icon {
  cursor: grabbing;
}
</style>
