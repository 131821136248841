<template>
  <SparkModal dismissable :model-value="show" @update:model-value="closeModal">
    <template #custom>
      <div class="text-19 font-bold p-20 border-b border-gray-100" v-text="'Add Custom Material'" />
      <CustomMaterialForm
        :show="show"
        :material-data="updatedFormData"
        mode="add"
        @close="closeModal"
        @add="addCustomMaterial"
        @prc-changed="filterMachines"
      />
    </template>
  </SparkModal>
</template>

<script>
import { mapMutations, mapState } from 'vuex';

import CustomMaterialForm from '../components/CustomMaterialForm.vue';

import SparkModal from '@/components/SparkComponents/SparkModal.vue';
import { transformObjectToArray } from '@/helpers';

export default {
  name: 'AddCustomMaterialModal',

  components: { CustomMaterialForm, SparkModal },

  props: {
    show: { type: Boolean, required: true },
    materialData: { type: Object, required: true },
    materialDataForm: { type: Object, required: true },
  },

  emits: ['close', 'add'],

  data() {
    return {
      updatedFormData: {},
    };
  },

  computed: {
    ...mapState(['filteredMachines']),

    updatedFilteredMachines() {
      return transformObjectToArray(this.filteredMachines);
    },
  },

  watch: {
    materialData: {
      handler(newVal) {
        if (newVal) {
          const updatedForm = this.populateFormValues(newVal, this.materialDataForm);
          this.updatedFormData = updatedForm;

          const prcField = this.updatedFormData['General'].find(field => field.db_name === 'prc_uid');
          const defaultProcess = this.materialData.general?.prc_uid;
          if (defaultProcess && prcField.options) {
            const process = prcField.options.find(opt => opt.value === defaultProcess);
            prcField.value = process ? process.value : '-';
          }

          const macField = this.updatedFormData['General'].find(field => field.db_name === 'mac_id');
          const defaultMachine = this.materialData.general?.default_machine_uid;
          if (defaultMachine && macField.options) {
            const machine = macField.options.find(opt => opt.value === defaultMachine);
            macField.value = machine ? machine.value : defaultMachine;
          }

          const matLvl2Field = this.updatedFormData['General'].find(field => field.db_name === 'mat_lvl_2_id');
          const defaultMatLvl2 = this.materialData.general?.mat_lvl_2_uid;
          if (defaultMatLvl2 && matLvl2Field.options) {
            const matLvl2 = matLvl2Field.options.find(opt => opt.value === defaultMatLvl2);
            matLvl2Field.value = matLvl2 ? matLvl2.value : defaultMatLvl2;
          }
        }
      },

      immediate: true,
      deep: true,
    },
  },

  methods: {
    ...mapMutations(['filterMachinesOnProcess']),

    closeModal() {
      this.$emit('close');
    },

    populateFormValues(materialData, addMaterialForm) {
      function getValueFromMaterialData(materialData, db_name) {
        const keys = db_name.split('.');
        let value = materialData;
        for (let key of keys) {
          value = value ? value[key] : null;
          if (value === null || value === undefined) break;
        }
        return value;
      }

      function assignValues(formArray, data) {
        formArray.forEach(formField => {
          if (formField.db_name) {
            let value;

            if (data.general && data.general[formField.db_name] !== undefined) {
              value = data.general[formField.db_name];
            } else {
              value = getValueFromMaterialData(data, formField.db_name);
            }

            if (value !== undefined && value !== null) {
              formField.value = value;
            }
          }
        });
      }

      for (const section in addMaterialForm) {
        if (Array.isArray(addMaterialForm[section])) {
          assignValues(addMaterialForm[section], materialData);
        }
      }

      return addMaterialForm;
    },

    filterMachines(data) {
      this.filterMachinesOnProcess(data.prcUid);
      const macField = this.updatedFormData['General'].find(field => field.db_name === 'mac_id');
      if (macField) {
        macField.options = this.updatedFilteredMachines;
        macField.value = data.macId;
      }
    },

    addCustomMaterial(data) {
      this.$emit('add', data);
      this.closeModal();
    },
  },
};
</script>
