<template>
  <div>
    <SparkModal custom="sm:!max-w-[90rem] h-[90vh]" :model-value="show" @update:model-value="close">
      <template #default>
        <div class="flex justify-between items-center">
          <div class="flex flex-col gap-4">
            <div class="text-17 font-bold">Production options</div>
            <div class="text-13">
              <div v-if="lockedForUser">
                <i class="fas fa-lock" />
                <div>You can only add production options to your own part.</div>
              </div>
              <div>Select process chains to be added to comparison.</div>
            </div>
          </div>
          <div class="pr-8" @click="close">
            <i class="fas fa-xmark text-17 text-gray-700 hover:text-gray-900 hover:cursor-pointer" />
          </div>
        </div>
        <div>
          <div>
            <div
              class="flex my-12 items-center border text-gray-600 justify-between rounded-8 border-gray-50 p-16 text-13"
            >
              <div class="flex gap-8">
                <div
                  class="p-8 rounded-4 cursor-pointer hover:bg-gray-50"
                  :class="selectedTab === 'template' ? ' bg-gray-50 text-primary-1000' : ''"
                  @click="switchTabs('template')"
                >
                  From Template
                </div>
                <div
                  class="p-8 rounded-4 cursor-pointer hover:bg-gray-50"
                  :class="selectedTab === 'material' ? ' bg-gray-50 text-primary-1000' : ''"
                  @click="switchTabs('material')"
                >
                  From Material
                </div>
              </div>

              <div v-if="selectedTab === 'template'" class="flex gap-16 justify-center items-center">
                <SparkCheckbox v-model="initialRowsHidden" name="hidden-rows">
                  <div class="text-13" v-text="'Hide 3D Spark templates'" />
                </SparkCheckbox>
                <div>
                  <SparkInput v-model="searchString" placeholder="Search" name="search" custom="mb-0" />
                  <div
                    v-if="searchString != ''"
                    class="text-9 text-right pr-4 text-gray-400 cursor-pointer hover:text-gray-800"
                    @click="searchString = ''"
                  >
                    Clear
                  </div>
                </div>
              </div>
            </div>
            <SuggestionTable
              v-if="selectedTab === 'template'"
              :initial-rows-hidden="initialRowsHidden"
              :search-string="searchString"
            />
            <ComparisonPageMaterialTable v-else @mat-id="addProcessChain($event)" />
          </div>
        </div>
      </template>
    </SparkModal>
  </div>
</template>

<script>
import { mapState, mapMutations } from 'vuex';

import ComparisonPageMaterialTable from '../../Material/ComparisonPageMaterialTable.vue';

import SuggestionTable from './SuggestionTable.vue';

import SparkCheckbox from '@/components/SparkComponents/SparkCheckbox.vue';
import SparkInput from '@/components/SparkComponents/SparkInput.vue';
import SparkModal from '@/components/SparkComponents/SparkModal.vue';

export default {
  name: 'CreateProcessChainModal',

  components: { SuggestionTable, SparkInput, ComparisonPageMaterialTable, SparkModal, SparkCheckbox },

  props: {
    show: { type: Boolean },
  },

  emits: ['close'],

  data() {
    return { selectedTab: 'template', initialRowsHidden: true, searchString: '' };
  },

  computed: {
    ...mapState(['part']),
    ...mapState('application', ['axiosInstance', 'lockedForUser']),
  },

  watch: {
    initialRowsHidden(value) {
      localStorage.setItem('hide-spark-templates', value);
    },
  },

  mounted() {
    this.initialRowsHidden = localStorage.getItem('hide-spark-templates') === 'true';
  },

  methods: {
    close() {
      this.$emit('close');
    },

    ...mapMutations({
      updateStorePart: 'updatePart',
    }),

    switchTabs(selection) {
      this.selectedTab = selection;
    },

    addProcessChain(formData) {
      this.axiosInstance
        .put(`/api/v1/part/${this.part.part_id}/`, formData)
        .then(response => {
          this.updateStorePart(response.data);
        })
        .catch(error => {
          console.log(error);
          this.boolProhibitRapidMaterialChange = false;
          this.$root.notify(
            'warning',
            'Material Selection Error',
            'An unexpected error occured with the material you selected. Please try again or try a different material. Please inform 3D Spark support if the error is persistent.',
            4000
          );
        });
    },
  },
};
</script>
