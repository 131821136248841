<template>
  <div class="bg-white border border-gray-100 mb-80 mr-8 relative rounded-8 min-w-[1000px]">
    <table class="w-full border-collapse">
      <thead class="h-56 text-15">
        <th />
        <InvestigationTitle
          title="Name"
          :active-sorting="sortingName == 'name'"
          :ascending="sortingAscending"
          sortby="name"
          @sort="sorting($event)"
        />
        <InvestigationTitle
          title="Lot Size"
          :active-sorting="sortingName == 'lot_size'"
          :ascending="sortingAscending"
          sortby="lot_size"
          @sort="sorting($event)"
        />
        <InvestigationTitle
          title="Feasibility"
          :active-sorting="sortingName == 'feasibility.redesign_required_weighted'"
          :ascending="sortingAscending"
          sortby="feasibility.redesign_required_weighted"
          @sort="sorting($event)"
        />
        <InvestigationTitle
          title="Cost"
          hover-title="Cost per Lot"
          :active-sorting="sortingName == 'cost.cost_pl'"
          :ascending="sortingAscending"
          sortby="cost.cost_pl"
          class="w-150"
          @sort="sorting($event)"
        />
        <InvestigationTitle
          title="Lead Time"
          hover-title="Lead Time per Lot"
          :active-sorting="sortingName == 'leadtime'"
          :ascending="sortingAscending"
          sortby="leadtime"
          class="w-150"
          @sort="sorting($event)"
        />
        <InvestigationTitle
          title="Carbon"
          :active-sorting="sortingName == 'co2.co2_pl'"
          :ascending="sortingAscending"
          sortby="co2.co2_pl"
          class="w-150"
          @sort="sorting($event)"
        />
        <InvestigationTitle title=" " class="sticky right-0 bg-white" sortby="" @sort="sorting($event)" />
      </thead>

      <tbody class="text-13 relative">
        <tr v-for="(key, index) in sortedChainIds" :key="key" class="h-64">
          <td v-if="processChains[key] === undefined" colspan="8"><div class="ml-16 text-gray-500">Deleted</div></td>
          <template v-else>
            <td
              class="w-16 border border-gray-50 hover:bg-gray-50 hover:cursor-pointer"
              :class="activeInvestigationDetails?.uid === key ? 'bg-primary-600' : 'bg-white'"
              @click="selectProcessChain(key)"
            />
            <td
              class="w-[300px] border border-gray-50 hover:bg-gray-50 hover:cursor-pointer"
              @mouseenter="mouseHover[key] = 'name'"
              @mouseleave="mouseHover[key] = ''"
            >
              <ProcessChainName :chain="processChains[key]" editable :mouse-hover="mouseHover[key]" />
            </td>
            <td class="border border-gray-50 w-80"><LotSize :chain="processChains[key]" /></td>
            <td
              class="border border-gray-50 hover:bg-gray-50 hover:cursor-pointer"
              @mouseenter="mouseHover[key] = 'feasibility'"
              @mouseleave="mouseHover[key] = ''"
            >
              <Feasibility :chain="processChains[key]" :mouse-hover="mouseHover[key]" />
            </td>
            <td
              class="border border-gray-50 hover:bg-gray-50 hover:cursor-pointer"
              @mouseenter="mouseHover[key] = 'cost'"
              @mouseleave="mouseHover[key] = ''"
            >
              <Cost :chain="processChains[key]" :chains="processChains" :mouse-hover="mouseHover[key]" />
            </td>
            <td
              class="border border-gray-50 hover:bg-gray-50 hover:cursor-pointer"
              @mouseenter="mouseHover[key] = 'leadtime'"
              @mouseleave="mouseHover[key] = ''"
            >
              <Leadtime :chain="processChains[key]" :chains="processChains" :mouse-hover="mouseHover[key]" />
            </td>
            <td
              class="border border-gray-50 hover:bg-gray-50 hover:cursor-pointer"
              @mouseenter="mouseHover[key] = 'carbon'"
              @mouseleave="mouseHover[key] = ''"
            >
              <CarbonFootprint :chain="processChains[key]" :chains="processChains" :mouse-hover="mouseHover[key]" />
            </td>
            <td class="border border-gray-50 right-0 sticky bg-white" :style="{ 'z-index': 20 - index }">
              <Checkout :chain="processChains[key]" />
            </td>
          </template>
        </tr>
        <tr>
          <td colspan="7">
            <div
              v-if="sortedChainIds.length === 0"
              class="font-bold p-12 hover:cursor-pointer hover:text-primary-600 w-fit"
              @click="showModal"
            >
              <SparkButton variant="secondary"> <i class="fas fa-plus" />Add production option </SparkButton>
            </div>
            <div v-else class="font-bold p-12 hover:cursor-pointer hover:text-primary-600 w-fit" @click="showModal">
              <i class="fas fa-plus" /> Add production option
            </div>
          </td>
        </tr>
      </tbody>
    </table>
    <CreateProcessChainModal :show="showCreateProcessChainModal" @close="closeModal" />
  </div>
</template>

<script>
import { mapState, mapMutations, mapGetters } from 'vuex';

import SparkButton from '../../../SparkComponents/SparkButton.vue';

import CreateProcessChainModal from './CreateProcessChainModal.vue';
import InvestigationTitle from './InvestigationTitle.vue';

import Checkout from '@/components/PartWorkflow/Comparison/tablecells/actions/Checkout.vue';
import CarbonFootprint from '@/components/PartWorkflow/Comparison/tablecells/carbon/CarbonFootprint.vue';
import Cost from '@/components/PartWorkflow/Comparison/tablecells/cost/Cost.vue';
import Feasibility from '@/components/PartWorkflow/Comparison/tablecells/feasibility/Feasibility.vue';
import Leadtime from '@/components/PartWorkflow/Comparison/tablecells/leadtime/Leadtime.vue';
import LotSize from '@/components/PartWorkflow/Comparison/tablecells/lotsize/LotSize.vue';
import ProcessChainName from '@/components/PartWorkflow/Comparison/tablecells/name/ProcessChainName.vue';
import { analysisFinished } from '@/composables/processChainHandling.js';
import { getValueOfNestedObject, compareValues } from '@/helpers.js';

export default {
  name: 'InvestigationTable',

  components: {
    ProcessChainName,
    LotSize,
    Feasibility,
    Cost,
    Leadtime,
    CarbonFootprint,
    Checkout,
    InvestigationTitle,
    CreateProcessChainModal,
    SparkButton,
  },

  data() {
    return {
      sortedChainIds: [],
      mouseHover: {},
      lastSortedBy: null,
      lastSortingAscending: null,

      showCreateProcessChainModal: false,
    };
  },

  computed: {
    ...mapState(['part', 'investigationDetails']),
    ...mapState('application', ['lockedTitle', 'axiosInstance']),
    ...mapGetters(['lockedForUser']),

    previousPageWasOverview() {
      let previousRoute = this.$router.options.history.state.back;
      let prevWasOverview = false;
      if (previousRoute != null) {
        let prev = previousRoute.toString();
        prevWasOverview = prev.includes('/ov/');
      }
      return prevWasOverview;
    },

    primary_process_chain_id() {
      return this.part?.primary_process_chain_id;
    },

    processChains() {
      return this.part.process_chains;
    },

    activeInvestigationDetails() {
      return this.investigationDetails;
    },

    sortingName() {
      return this.part.sorting_name;
    },

    sortingAscending() {
      return this.part.sorting_ascending;
    },
  },

  watch: {
    processChains: {
      handler(newVal, oldVal) {
        const newKeys = Object.keys(newVal);
        const oldKeys = Object.keys(oldVal);

        if (newKeys.length !== oldKeys.length || !newKeys.every(key => oldKeys.includes(key))) {
          // Only resort if the number of chains has changed or if there are new/removed chains
          this.sortByHeader(this.lastSortedBy || this.sortingName, this.lastSortingAscending ?? this.sortingAscending);
        } else {
          // If the chains are the same, just update the sorted order without changing it
          this.updateSortedOrder();
        }
      },

      deep: true,
    },
  },

  mounted() {
    this.initializeSort();
  },

  beforeMount() {
    if (!this.previousPageWasOverview) {
      this.$store.commit('changeInvestigationDetails', '');
    }
  },

  methods: {
    ...mapMutations(['changeInvestigationDetails']),

    selectProcessChain(uid) {
      this.changeInvestigationDetails({ uid: uid });
    },

    showModal() {
      this.changeInvestigationDetails({ uid: '' });
      this.showCreateProcessChainModal = true;
    },

    closeModal() {
      this.showCreateProcessChainModal = false;
    },

    analysisFinished(uid) {
      return analysisFinished(uid);
    },

    sorting(event) {
      this.$store.commit('setProcessChainSorting', { sorting_name: event.sortby, sorting_ascending: event.ascending });
      this.axiosInstance.put(`/api/v1/part/${this.part.part_id}/`, {
        sorting_name: event.sortby,
        sorting_ascending: event.ascending,
      });

      if (event.sortby) {
        this.sortByHeader(event.sortby, event.ascending);
      } else {
        this.sortByMain();
      }
    },

    sortByHeader(newSortBy, newSortingAscending) {
      this.lastSortedBy = newSortBy;
      this.lastSortingAscending = newSortingAscending;

      this.changeInvestigationDetails('');
      if (!this.previousPageWasOverview) {
        this.changeInvestigationDetails('');
      }

      this.updateSortedOrder();
    },

    updateSortedOrder() {
      const newSortBy = this.lastSortedBy || this.sortingName;
      const newSortingAscending = this.lastSortingAscending ?? this.sortingAscending;

      let loopUpValueUid = {};
      let process_chain_ids = Object.keys(this.part.process_chains);

      for (const [key, value] of Object.entries(this.processChains)) {
        if (process_chain_ids.includes(key)) {
          let v = newSortBy ? getValueOfNestedObject(value, newSortBy) : null;
          loopUpValueUid[key] = v;
        }
      }

      let sortedEntries = Object.entries(loopUpValueUid).sort((a, b) => {
        if (a[1] === null && b[1] === null) return 0;
        if (a[1] === null) return newSortingAscending ? 1 : -1;
        if (b[1] === null) return newSortingAscending ? -1 : 1;
        return newSortingAscending ? compareValues(a[1], b[1]) : compareValues(b[1], a[1]);
      });

      this.sortedChainIds = sortedEntries.map(entry => entry[0]);
    },

    sortByMain() {
      this.changeInvestigationDetails('');
      if (!this.previousPageWasOverview) {
        this.changeInvestigationDetails('');
      }

      let process_chain_ids = Object.keys(this.part.process_chains);

      let sorted_ids = [];
      if (
        this.primary_process_chain_id != null &&
        this.primary_process_chain_id != '' &&
        process_chain_ids.includes(this.primary_process_chain_id)
      ) {
        sorted_ids = [this.primary_process_chain_id];
      }
      const other_ids = process_chain_ids.filter(element => !sorted_ids.includes(element));

      let all_sorted_ids = sorted_ids.concat(other_ids);

      this.sortedChainIds = all_sorted_ids;
    },

    initializeSort() {
      if (!this.sortingName) {
        this.sortByMain();
      } else {
        this.sortByHeader(this.sortingName, this.sortingAscending);
      }
    },
  },
};
</script>
