<template>
  <div class="flex gap-10">
    <AnalysisButton />
    <Profile />
  </div>
</template>

<script>
import AnalysisButton from '@/components/PartWorkflow/Comparison/components/AnalysisButton.vue';
import Profile from '@/components/PartWorkflow/Comparison/components/Profile.vue';

export default {
  name: 'PartActionButtonsProduction',

  components: { AnalysisButton, Profile },

  data() {
    return {};
  },

  computed: {},
};
</script>
