<template>
  <div v-if="user.is_external !== undefined">
    <div v-if="loading">
      <Loading />
    </div>

    <div v-else class="grid">
      <img
        v-if="user.is_external"
        style="position: absolute; opacity: 0.2; right: 100px; top: 70px"
        src="@/assets/img/Logo_Transparent.png"
      />
      <div class="header">Welcome, {{ user.first_name || user.username }}!</div>

      <div class="box11 card" @mouseover="mouseHoverBox11 = true" @mouseleave="mouseHoverBox11 = false">
        <div>
          <div class="card-title-wrapper">
            <h3 class="card-title">Your Uploads</h3>
            <div class="card-icon">
              <i class="fas fa-cube" />
            </div>
          </div>

          <div class="card-content"><NumberAnimation id="youruploads" :count="ownUploads" /></div>
          <div class="card-date">
            <i class="fas fa-arrow-up" /> &nbsp; {{ ownUploadsWeek }} &nbsp; reference parts this week
          </div>

          <div v-for="(name, uid) in lastParts" :key="uid" class="card-additional">
            <div>
              <router-link
                style="text-decoration: none; color: black"
                :to="{
                  name: 'partframe',
                  params: { part_id: uid },
                }"
              >
                {{ name }}
              </router-link>
            </div>
          </div>
          <CardArrow
            arrow-title="Create a new part"
            to-route="/partframe/0/int"
            :mouse-hover="mouseHoverBox11"
            @click="resetPart"
          />
          <div class="hidden-element">&nbsp;</div>
        </div>
      </div>

      <div
        v-if="!user.is_external"
        class="box12 card"
        @mouseover="mouseHoverBox12 = true"
        @mouseleave="mouseHoverBox12 = false"
      >
        <div class="card-title-wrapper">
          <h3 class="card-title">Organization Uploads</h3>
          <div class="card-icon">
            <i class="fas fa-cubes" />
          </div>
        </div>
        <div class="card-content"><NumberAnimation id="alluploads" :count="allUploads" /></div>
        <div class="card-date">
          <i class="fas fa-arrow-up" />&nbsp; {{ allUploadsWeek }} &nbsp; reference parts this week
        </div>
        <CardArrow arrow-title="Go to Library" to-route="/library/part" :mouse-hover="mouseHoverBox12" />
      </div>

      <div
        v-if="!user.is_external"
        class="box13 card"
        @mouseover="mouseHoverBox13 = true"
        @mouseleave="mouseHoverBox13 = false"
      >
        <ComparisonChart :chart-data="chartDataCostSaved" chart-title="Cost Saved in €" />
        <ComparisonChart :chart-data="chartDataUploads" chart-title="Reference Part Uploads" />
        <CardArrow arrow-title="Go to Dashboard" to-route="/dashboard" :mouse-hover="mouseHoverBox13" />
      </div>

      <div class="box21 card card-feed">
        <div class="card-header">
          <h3 class="card-title">Newsfeed</h3>
          <DropdownMenu
            :list-options="dropdownOptions"
            title-icon="fas fa-ellipsis-h"
            close-click-style="always"
            :title-style="{ 'font-size': 'var(--20px)', padding: '1px 0px 0px 0px' }"
            @update-option="show($event)"
          />
        </div>
        <Newsfeed :fetch-news="fetchNews" />
      </div>
      <div class="box22 card card-feed">
        <h3 class="card-title" style="margin-bottom: 20px">References</h3>
        <References />
      </div>
    </div>
    <RoleModal :show="showRoleModal" @close="closeModal()" />
  </div>
</template>

<script>
import { mapState, mapMutations } from 'vuex';

import CardArrow from './CardArrow.vue';
import ComparisonChart from './ComparisonChart.vue';
import Newsfeed from './Newsfeed.vue';
import References from './References.vue';
import RoleModal from './RoleModal.vue';

import DropdownMenu from '@/components/Reusable/DropdownMenu.vue';
import Loading from '@/components/Reusable/Loading.vue';
import NumberAnimation from '@/components/Reusable/NumberAnimation.vue';
export default {
  name: 'LandingPage',

  components: {
    NumberAnimation,
    Loading,
    ComparisonChart,
    References,
    Newsfeed,
    CardArrow,
    DropdownMenu,
    RoleModal,
  },

  data() {
    return {
      loading: true,
      allUploads: 0,
      allUploadsWeek: 0,
      ownUploads: 0,
      ownUploadsWeek: 0,
      lastParts: {},
      chartDataCostSaved: {},
      chartDataUploads: {},
      mouseHoverBox11: false,
      mouseHoverBox12: false,
      mouseHoverBox13: false,
      fetchNews: 0,
      showRoleModal: false,
    };
  },

  computed: {
    ...mapState(['user', 'organization']),
    ...mapState('application', ['axiosInstance']),

    dropdownOptions() {
      let options = [{ text: 'Show old news', value: 'fetchNews', icon: 'fas fa-newspaper', disabled: false }];
      return options;
    },
  },

  watch: {
    user: {
      handler() {
        if (this.user.is_external) {
          this.$router.push({ name: 'partframe', params: { part_id: '0' } });
        }
      },

      immediate: true,
    },
  },

  created() {
    this.getData();
  },

  mounted() {
    if (this.user.organization_role === '' || this.user.organization_role == undefined) {
      this.showRoleModal = true;
    }
  },

  methods: {
    ...mapMutations(['updateActiveContent', 'updateActiveId', 'setPartCategories']),
    ...mapMutations('canvas', ['resetCanvas']),
    ...mapMutations({
      resetPartToDefault: 'resetPart',
    }),

    show(event) {
      if (event.value == 'fetchNews') {
        this.fetchNews += 1;
      }
    },

    closeModal() {
      this.showRoleModal = false;
    },

    resetPart() {
      /*
      Reset all stored data
      */
      this.resetPartToDefault();
      this.updateActiveContent('Input');
      this.updateActiveId('');
      this.setPartCategories();
      this.resetCanvas();
    },

    async getData() {
      this.loading = true;
      // only send request when not loading
      await this.axiosInstance
        .get(`api/v1/landing-page/`)
        .then(response => {
          this.loading = false;
          this.allUploads = response.data.all_uploads;
          this.allUploadsWeek = response.data.all_uploads_week;
          this.ownUploads = response.data.own_uploads;
          this.ownUploadsWeek = response.data.own_uploads_week;
          this.lastParts = response.data.last_parts;

          this.chartDataCostSaved = {
            name: 'Cost Savings in €',
            data: [
              {
                x: 'You',
                y: response.data.own_cost_saved,
                fillColor: '#00293c',
              },
              {
                x: 'Average per User',
                y: response.data.avg_cost_saved_per_user,
                fillColor: '#005364',
              },
            ],
          };
          this.chartDataUploads = {
            name: 'Reference Part Uploads',
            data: [
              {
                x: 'You',
                y: response.data.own_uploads,
                fillColor: '#00293c',
              },
              {
                x: 'Average per User',
                y: response.data.avg_uploads_per_user,
                fillColor: '#005364',
              },
            ],
          };
        })
        .catch(error => {
          console.error(error);
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.header {
  grid-area: hd0;
  padding: 30px;
  margin-bottom: 5px;
}

.box11 {
  grid-area: box11;
}

.box12 {
  grid-area: box12;
}

.box13 {
  grid-area: box13;
  // min-width: 600px;
  text-align: center;
}

.box21 {
  grid-area: box21;
  max-height: 450px;
}

.box22 {
  grid-area: box22;
}

.grid {
  display: grid;
  align-items: stretch;
  margin: 10px;
  grid-auto-rows: minmax(120px, auto);
  grid-template-columns: repeat(1, 1fr);
  grid:
    'hd0'
    'box11'
    'box12'
    'box13'
    'box21'
    'box22';
}

@media only screen and (min-width: 800px) {
  .grid {
    display: grid;
    align-items: stretch;
    margin: 50px;
    grid-auto-rows: minmax(120px, auto);
    grid-template-columns: repeat(6, 1fr);
    grid:
      'hd0   hd0   hd0 hd0 box13 box13'
      'box11 box11 box12 box12 box13 box13'
      'box21 box21 box21 box21 box22 box22';
  }
}

.card-header {
  display: flex;
  justify-content: space-between;
}
</style>
