<template>
  <div class="flex-col p-20 border border-gray-50 rounded-8 m-8">
    <div class="text-15 font-bold">Organization Goals</div>

    <div v-for="(goals, year) in groupedGoals" :key="year" class="organization-goal-settings__acordions">
      <div class="title" @click="setExpandedYear(year)">
        <span class="heading">
          <i :class="[expandedYear === year ? 'fas fa-caret-down' : 'fas fa-caret-right']" /> {{ year }}
        </span>
      </div>
      <transition name="accordion" @enter="start" @after-enter="end" @before-leave="start" @after-leave="end">
        <div v-show="expandedYear === year">
          <table class="spark-table">
            <tr>
              <th />
              <th class="goal-data">Name</th>
              <th title="Number of unique validated parts." class="goal-data">References</th>
              <th class="goal-data">Cost Saved (€)</th>
              <th class="goal-data">Leadtime Saved (days)</th>
              <th class="goal-data">CO2 Saved (kgCO₂-eq)</th>
              <th title="Optional category to filter references for this goal." class="goal-data">Filter</th>
              <th />
            </tr>
            <tr v-for="goalData in goals" :key="goalData.uid">
              <td>{{ goalData.title }}</td>
              <td>{{ goalData.name }}</td>
              <td>
                <p class="goal-data">{{ goalData.partsValidated }}</p>
              </td>
              <td>
                <p class="goal-data">{{ goalData.costSaved }}</p>
              </td>
              <td>
                <p class="goal-data">{{ goalData.leadTimeSaved }}</p>
              </td>
              <td>
                <p class="goal-data">{{ goalData.co2Saved }}</p>
              </td>
              <td>
                <p class="goal-data">{{ goalData.comboCategoryFilterValue }}</p>
              </td>
              <td>
                <div class="icons">
                  <i class="far fa-edit" @click="editGoal(goalData)" />
                  <i class="far fa-trash-alt" @click="deleteGoal(goalData)" />
                </div>
              </td>
            </tr>
          </table>
        </div>
      </transition>
    </div>

    <p class="add-goal" @click="showAddGoalDialog = true">Add Goal</p>
    <div v-if="showAddGoalDialog">
      <table class="spark-table">
        <tbody>
          <tr>
            <th class="goal-data">Start Date</th>
            <th class="goal-data">End Date</th>
            <th class="goal-data">Name</th>
            <th class="goal-data">References</th>
            <th class="goal-data">Cost Saved (€)</th>
            <th class="goal-data">Leadtime Saved (days)</th>
            <th class="goal-data">CO2 Saved (kgCO₂-eq)</th>
            <th class="goal-data">Filter</th>
          </tr>
          <tr>
            <td>
              <input
                id="period-start"
                v-model="formData.periodStart"
                class="inputs"
                type="date"
                placeholder="Period Start"
              />
            </td>
            <td>
              <input id="period-end" v-model="formData.periodEnd" class="inputs" type="date" placeholder="Period End" />
            </td>
            <td>
              <input id="goal-name" v-model="formData.name" class="inputs" type="text" placeholder="Name" />
            </td>
            <td>
              <input
                id="parts-validated"
                v-model="formData.partsValidated"
                class="inputs"
                type="text"
                placeholder="References"
              />
            </td>
            <td>
              <input id="cost-saved" v-model="formData.costSaved" class="inputs" type="text" placeholder="Cost Saved" />
            </td>
            <td>
              <input
                id="leadtime-saved"
                v-model="formData.leadTimeSaved"
                class="inputs"
                type="text"
                placeholder="Leadtime Saved"
              />
            </td>
            <td>
              <input id="co2-saved" v-model="formData.co2Saved" class="inputs" type="text" placeholder="CO2 Saved" />
            </td>
            <td>
              <input
                id="category-filter"
                v-model="filterCategoryItem"
                class="inputs add-category-filter"
                type="text"
                title="Add Category Filter"
                readonly
                @click="openFilterModal"
              />
            </td>
            <td>
              <i class="fas fa-times times-icon" @click="closeAddGoalDialog" />
              <i class="fas fa-check times-icon" @click="validateAndSaveGoals" />
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <SelectComboCategoryItemModal
      :show="showFilterModal"
      :combo-categories="comboCategories"
      @close="closeFilterModal"
      @update-filter="updateFilter"
    />
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';

import SelectComboCategoryItemModal from '../../../../components/modals/SelectComboCategoryItemModal.vue';

export default {
  name: 'OrganizationGoalSettings',

  components: { SelectComboCategoryItemModal },

  data() {
    return {
      groupedGoals: [],
      formData: {
        name: '',
        periodStart: '',
        periodEnd: '',
        co2Saved: null,
        leadTimeSaved: null,
        costSaved: null,
        partsValidated: null,
        filterCategoryItemUid: null,
        comboCategoryFilterValue: '',
      },

      filterCategoryItem: '',
      showFilterModal: false,
      validationRegex: {
        integer: /^[1-9]\d*$/,
        float: /^\d+(\.\d+)?$/,
      },

      expandedYear: '',
      showAddGoalDialog: false,
      currentGoalUid: null,
    };
  },

  computed: {
    ...mapState(['organizationGoal', 'user', 'organization']),
    comboCategories() {
      return this.organization.combo_categories;
    },
  },

  watch: {
    organizationGoal: {
      handler: function () {
        this.groupedGoals = this.groupGoalsByYear(this.organizationGoal);
      },
    },
  },

  mounted() {
    this.fetchGoals();
  },

  methods: {
    ...mapActions(['addorganizationGoal', 'deleteorganizationGoal', 'fetchOrganizationGoal', 'updateorganizationGoal']),

    start(el) {
      el.style.height = el.scrollHeight + 'px';
    },

    end(el) {
      el.style.height = '';
    },

    openFilterModal() {
      this.showFilterModal = true;
    },

    closeFilterModal() {
      this.showFilterModal = false;
    },

    updateFilter(filter) {
      this.filterCategoryItem = `${filter.item}`;
      this.formData.filterCategoryItemUid = filter.itemUid || null;
      this.formData.comboCategoryFilterValue = filter.item;
    },

    async fetchGoals() {
      try {
        const response = await this.fetchOrganizationGoal();
        if (!response.status === 200) {
          this.$root.notify('error', 'Failed', 'Failed to fetch goals', 3000);
        }
        this.groupedGoals = this.groupGoalsByYear(this.organizationGoal);
        if (Object.keys(this.groupedGoals).length > 0) {
          this.expandedYear = Object.keys(this.groupedGoals)[0];
        }
      } catch (error) {
        this.$root.notify('error', 'Error', 'An error occurred while fetching the goals.', 3000);
      }
    },

    async updateGoal(goal) {
      try {
        await this.updateorganizationGoal(goal);
        this.$root.notify('success', 'Updated', 'Goal updated successfully.', 3000);
      } catch (error) {
        this.$root.notify('error', 'Error', 'An error occurred while updating the goal.', 3000);
      }
    },

    async deleteGoal(goal) {
      try {
        const confirmDelete = confirm(`Are you sure you want to delete this goal?`);
        if (confirmDelete) {
          await this.deleteorganizationGoal(goal.uid);
          this.$root.notify('success', 'Deleted', 'Goal deleted successfully.', 3000);
        }
      } catch (error) {
        this.$root.notify('error', 'Error', 'An Error occurred while deleting goal.', 3000);
      }
    },

    async validateAndSaveGoals() {
      if (
        !this.formData.co2Saved &&
        !this.formData.leadTimeSaved &&
        !this.formData.costSaved &&
        !this.formData.partsValidated &&
        !this.formData.name
      ) {
        this.$root.notify('error', 'Error', 'Please fill in at least one field.', 3000);
        return;
      }

      if (
        (this.formData.co2Saved && !this.validateformField(this.formData.co2Saved, this.validationRegex.float)) ||
        (this.formData.leadTimeSaved &&
          !this.validateformField(this.formData.leadTimeSaved, this.validationRegex.integer)) ||
        (this.formData.costSaved && !this.validateformField(this.formData.costSaved, this.validationRegex.float)) ||
        (this.formData.partsValidated &&
          !this.validateformField(this.formData.partsValidated, this.validationRegex.integer))
      ) {
        this.$root.notify('error', 'Error', 'Please enter valid values for the fields filled.', 3000);
        return;
      }

      const goalData = {
        name: this.formData.name,
        periodStart: this.formData.periodStart,
        periodEnd: this.formData.periodEnd,
        co2Saved: this.formData.co2Saved ? Number(this.formData.co2Saved) : null,
        leadTimeSaved: this.formData.leadTimeSaved ? Number(this.formData.leadTimeSaved) : null,
        costSaved: this.formData.costSaved ? Number(this.formData.costSaved) : null,
        partsValidated: this.formData.partsValidated ? Number(this.formData.partsValidated) : null,
        filterCategoryItemUid: this.formData.filterCategoryItemUid,
      };

      if (this.currentGoalUid) {
        await this.updateGoal({
          uid: this.currentGoalUid,
          goal: goalData,
        });
      } else {
        try {
          await this.addorganizationGoal(goalData);
          this.$root.notify('success', 'Added', 'Goal added successfully.', 3000);
        } catch (error) {
          this.$root.notify('error', 'Error', 'An error occurred while adding the goals.', 3000);
        }
      }

      this.resetFormData();
      this.closeAddGoalDialog();
    },

    groupGoalsByYear(goals) {
      const groupedGoals = goals.reduce((result, goal) => {
        const year = new Date(goal.periodStart).getFullYear();

        if (!result[year]) {
          result[year] = [];
        }

        result[year].push(goal);
        return result;
      }, {});

      return groupedGoals;
    },

    editGoal(goal) {
      this.formData.name = goal.name;
      this.formData.periodStart = goal.periodStart.split('T')[0];
      this.formData.periodEnd = goal.periodEnd.split('T')[0];
      this.formData.co2Saved = goal.co2Saved;
      this.formData.leadTimeSaved = goal.leadTimeSaved;
      this.formData.costSaved = goal.costSaved;
      this.formData.partsValidated = goal.partsValidated;
      this.filterCategoryItem = goal.comboCategoryFilterValue || '';
      this.currentGoalUid = goal.uid;
      this.showAddGoalDialog = true;
    },

    setExpandedYear(year) {
      if (this.expandedYear === year) return (this.expandedYear = '');
      this.expandedYear = year;
    },

    resetFormData() {
      this.formData.name = '';
      this.formData.periodStart = '';
      this.formData.periodEnd = '';
      this.formData.co2Saved = null;
      this.formData.leadTimeSaved = null;
      this.formData.costSaved = null;
      this.formData.partsValidated = null;
      this.formData.filterCategoryItemUid = null;
      this.formData.comboCategoryFilterValue = '';
      this.filterCategoryItem = '';
    },

    closeAddGoalDialog() {
      this.resetFormData();
      this.showAddGoalDialog = false;
      this.currentGoalUid = null;
    },

    validateformField(input, regex) {
      if (!regex.test(input)) return false;
      return true;
    },
  },
};
</script>

<style lang="scss" scoped>
.organization-goal-settings__acordions {
  margin-bottom: 12px;

  &:last-child {
    margin-bottom: 0;
  }
}

.title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 8px;
  background: none;
}

.icons {
  display: flex;
  gap: 8px;
  cursor: pointer;
}

.heading {
  display: flex;
  align-items: center;
  gap: 8px;
  cursor: pointer;
  padding: 0 8px;
}

.accordion-enter-active,
.accordion-leave-active {
  transition:
    height 0.3s ease-in,
    opacity 0.3s ease;
}

.accordion-enter,
.accordion-leave-to {
  height: 0 !important;
  opacity: 0;
}

.inputs {
  &:hover {
    border-width: 1px;
  }
}

.goal-heading {
  text-align: center;
}

.goal-data {
  text-align: center;
  border: none;
}

.year-input {
  width: auto;
}

.times-icon {
  margin-right: 5px;
  cursor: pointer;
}

.add-goal {
  text-decoration: underline;
  font-size: 13px;
  cursor: pointer;
  width: fit-content;
}

.add-category-filter {
  cursor: pointer;
}
</style>
