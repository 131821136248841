<template>
  <div v-if="Object.keys(externalMarketPrices).length != 0" class="mr-32">
    {{ $formatTwoDecimalPlaces(minimumPrice) }} <span class="text-gray-400">€</span> -
    {{ $formatTwoDecimalPlaces(maximumPrice) }} <span class="text-gray-400">€</span>
  </div>
</template>

<script>
import { mapState, mapMutations } from 'vuex';

export default {
  name: 'ExternalMarketPrice',

  computed: {
    ...mapState({ storePart: 'part' }),
    ...mapState(['actualMarketPriceRequest']),
    ...mapState('application', ['axiosInstance']),

    externalMarketPrices() {
      if (!this.part.market_prices_api) {
        return {};
      }
      return Object.fromEntries(
        Object.entries(this.part.market_prices_api).filter(([key]) => key !== 'supplier_panel')
      );
    },

    maximumPrice() {
      if (!this.part.market_prices_api) {
        return 0;
      }
      const prices = Object.entries(this.part.market_prices_api)
        .filter(([key]) => key !== 'supplier_panel') // Filter out supplier_panel
        .map(([_, value]) => value.price)
        .filter(price => !isNaN(price)); // Filter out any NaN values

      if (prices.length === 0) {
        return 0;
      }
      return Math.max(...prices);
    },

    minimumPrice() {
      if (!this.part.market_prices_api) {
        return 0;
      }
      const prices = Object.entries(this.part.market_prices_api)
        .filter(([key]) => key !== 'supplier_panel') // Filter out supplier_panel
        .map(([_, value]) => value.price)
        .filter(price => !isNaN(price)); // Filter out any NaN values

      if (prices.length === 0) {
        return 0;
      }
      return Math.min(...prices);
    },

    part: {
      get() {
        return this.storePart;
      },

      set(value) {
        this.updatePart(value);
      },
    },
  },

  mounted() {
    if (Object.keys(this.externalMarketPrices).length == 0) {
      this.requestMarketPrice();
    }
  },

  methods: {
    ...mapMutations(['updatePart', 'updateExternalMarketPrice']),

    async requestMarketPrice() {
      let partId = this.storePart.part_id;
      if (!partId) {
        partId = this.$route.params.part_id;
      }
      this.axiosInstance
        .get(`/api/v1/market-price/${partId}`)
        .then(response => {
          this.updateExternalMarketPrice(response.data);
        })
        .catch(error => {
          console.log(error);
        });
    },
  },
};
</script>
